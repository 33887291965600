import { Switch, Route } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { GlobalContextState } from "../context/globalContext";
import { Layout, Box } from "@strapi/design-system";
import SideNav from "../components/SideNav/SideNav";
import ClaimDetails from "./ClaimDetails";
import { Redirect } from "react-router-dom";
import ClaimsList from '../components/Claim/ClaimsList';
import Create from '../components/Claim/Create';
import { MenuBurger } from "@strapi/icons";
import { Exit } from "@strapi/icons";

export default function Claims() {
  const user = useContext(GlobalContextState).user;
  const token  = useContext(GlobalContextState).token;

  useEffect(() => {
    const script = document.createElement('script');
    if(user){
      const credentials = {
        token: token,
        user: {
            email: user.email,
            username: user.username,
            id: user.id,
            type: user.superAdmin
        }
      }

      const scriptText = document.createTextNode(`
        chrome.runtime.sendMessage(
          'egepamljlmjcnnhbenimmbopbfpbecee',
          { type: 'logged-in', data: '${JSON.stringify(credentials)}' },
          { includeTlsChannelId: true },
           function(response) {
            console.log('response', response);
           }
        );
      `);
      script.appendChild(scriptText);
      document.body.appendChild(script);
    }
  }, []);

  const userSubscription = user.subscription ? "active" : "inactive";
  if(userSubscription === "inactive") {
    return <Redirect to="/settings/subscription" />;
  }

  function toogleSideNav() {
    const sideNav = document.querySelector('.main-navigation');
    sideNav.classList.toggle('active');
  }

  return (
    <Box background="neutral100">
      <div className="mobile-nav">
        <img src="/assets/images/tc_logo_white-49bdf314.png" alt="Strapi logo" />
        <button className="mobile-nav__burger" onClick={toogleSideNav}>
          <MenuBurger />
        </button>
      </div>
      <Layout sideNav={<SideNav />}>
        <header className="header flex w-full justify-end px-14 pt-3">
          <a href="/logout" className="logout flex">Logout&nbsp;&nbsp;<Exit /></a>
        </header>
        <main className="flex-1">
          <Switch>
            <Route 
              exact 
              path="/claims" 
              render={() => <ClaimsList />} />
            <Route
              path="/claims/create"
              render={() => <Create />}
            />
            <Route
              path="/claims/claim-details/:urlkey"
              render={() => <ClaimDetails />}
            />
          </Switch>
        </main>
      </Layout>
    </Box>
  );
}
