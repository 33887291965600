import React from "react";
import { useState, useContext, useRef, useEffect } from "react";
import { Divider } from "@strapi/design-system";
import Tooltip from '@mui/material/Tooltip';
import {
  MainNav,
  NavSections,
  NavCondense,
  NavBrand,
  NavUser,
  NavLink,
  NavFooter
} from "@strapi/design-system/v2";
import {
  Write,
  User,
  Cog,
  Dashboard,
  Book,
  Cross,
  Download
} from "@strapi/icons";
import ShareIcon from '../Icons/ShareIcon';
import ClipboardIcon from '../Icons/ClipboardIcon';
import { NavLink as RouterNavLink } from "react-router-dom";
import { GlobalContextState } from "../../context/globalContext";
import { Exit } from "@strapi/icons";

export default function SideNav() {
  const [condensed, setCondensed] = useState(false);
  const [copiedString, setCopiedString] = useState("");
  const [isChrome, setIsChrome] = useState(false);
  const user = useContext(GlobalContextState).user;
  const buttonRef = useRef(null);
  let navigation = [];

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const chrome = /Chrome/.test(userAgent) && /Google Inc/.test(window.navigator.vendor);

    setIsChrome(chrome);
  }, []);

  const icons = {
    Write: <Write />,
    User: <User />,
    Cog: <Cog />,
    Dashboard: <Dashboard />,
    Book: <Book />,
    Exit: <Exit />,
    Download: <Download />,
    ShareIcon: <ShareIcon />
  };

  if(user.subscription){
    navigation = [
      {
        name: "Claims",
        pathname: "/claims",
        current: false,
        icon: "Write",
        key: 0
      }
    ];
  }

  function toogleSideNav() {
    const sideNav = document.querySelector('.main-navigation');
    sideNav.classList.toggle('active');
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedString(text);

    setTimeout(() => {
      setCopiedString("");
    }, 2000);
  }

  return (
    <div className="main-navigation">
      <MainNav condensed={condensed}>
        <div className="mobile-nav nav-left">
          {condensed ? (
            <img src="/assets/images/tc-icon-48.png" alt="Strapi logo" />
          ) : (
            <img src="/assets/images/tc_logo_white-49bdf314.png" alt="Strapi logo" />
          )
        }
          
          <button className="mobile-nav__burger" onClick={toogleSideNav}>
            <Cross />
          </button>
        </div>
        <Divider />
        <NavSections>
          {user.superAdmin && (
            <NavLink
            as={RouterNavLink}
            to="/dashboard"
            icon={icons["Dashboard"]}
          >
            Dashboard
          </NavLink>
          )}
          {navigation.map((item, key) => (
            <NavLink
              key={key}
              as={RouterNavLink}
              to={item.pathname}
              icon={icons[item.icon]}
            >
              {item.name}
            </NavLink>
          ))}
          <NavLink
            as={RouterNavLink}
            to="/settings"
            icon={icons["Cog"]}
          >
            Settings
          </NavLink>
          <NavLink
            as={RouterNavLink}
            to="/documents"
            icon={icons["Book"]}
          >
            Documentation
          </NavLink>
          {user.superAdmin && user.shareUrl && (
            <>
            <NavLink
              className="share-link"
              as={RouterNavLink}
              to={user.shareUrl} // Keep the 'to' prop
              onClick={(e) => {
                e.preventDefault(); // Prevent navigation
                copyToClipboard(user.shareUrl); // Call the copy function
              }}
              icon={icons["ShareIcon"]}
            >
              Referral Link 
            </NavLink>

            <span 
            onClick={() => copyToClipboard(user.shareUrl)}
            className="clipboard-icon">
              <Tooltip title={copiedString === user.shareUrl ? "Copied!" : "Copy Referral Link"} arrow>
                <ClipboardIcon /><span>Copy</span>
              </Tooltip>
            </span> 
            </>
          )}
          {isChrome && (
            <NavLink
              href="https://chromewebstore.google.com/detail/trueclaimai/egepamljlmjcnnhbenimmbopbfpbecee"
              target="_blank"
              icon={icons["Download"]}
            >
              Extension
            </NavLink>
          )}
        </NavSections>
        <NavFooter>
          <NavUser
            initials={user.username.charAt(0).toUpperCase()}
            ref={buttonRef}
          >
            {user && user.username}
          </NavUser>
          <NavCondense className="desktop-v" onClick={() => setCondensed((s) => !s)}>
            {condensed ? "Expanded the navbar" : "Collapse the navbar"}
          </NavCondense>
        </NavFooter>
      </MainNav>
    </div>
  );
}
