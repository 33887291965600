import { Switch, Route } from "react-router-dom";
import React from "react";
import SideNav from "../components/SideNav/SideNav";
import { Layout, Box } from "@strapi/design-system";
import DocumentView from '../components/Dashboard/DocumentView';
import { MenuBurger } from "@strapi/icons";
import { Exit } from "@strapi/icons";

export default function Document() {

  function toogleSideNav() {
    const sideNav = document.querySelector('.main-navigation');
    sideNav.classList.toggle('active');
  }

  return (
    <Box background="neutral100">
      <div className="mobile-nav">
        <img src="/assets/images/tc_logo_white-49bdf314.png" alt="Strapi logo" />
        <button className="mobile-nav__burger" onClick={toogleSideNav}>
          <MenuBurger />
        </button>
      </div>
      <Layout sideNav={<SideNav />}>
        <header className="header flex w-full justify-end px-14 pt-3">
          <a href="/logout" className="logout flex">Logout&nbsp;&nbsp;<Exit /></a>
        </header>
        <main className="flex-1">
          <Switch>
            <Route exact path="/documents" render={() => <DocumentView />} />
          </Switch>
        </main>
      </Layout>
    </Box>
  );
}
