import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import parse, { domToReact } from "html-react-parser";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { baseUrl } from "../config";
import { GlobalContextState } from "../context/globalContext";
import { LoadingSpinner as LoadingSpinner1 } from "../components/ui/loading-spinner";
import "../components/ClaimReport/Report.css";
import { ChatList } from "../components/Chat/chat-list";
import ImageGallery from "../components/Claim/ImageGallery";
import PoiSvg from "../components/Claim/PoiSvg";
import ImagesOpline from "../components/Claim/ImagesOpline";
import Adas from "../components/Claim/Adas";
import Trail from "../components/Claim/Trail";
import {Helmet} from "react-helmet";
import Drawer from '@mui/material/Drawer';

/* Import data */
import classParts from "../datamap/classParts";
import poiParts from "../datamap/poiParts";
import bmsProfileType from "../datamap/bmsProfileType";
import labelWithKey from "../datamap/labelWithKey";

/* Import components */
import ClaimDetailLogs from "../components/SuperAdmin/ClaimDetailLogs";

import { Table, Thead, Tbody, Tr, Td, Th, Layout, BaseHeaderLayout, ContentLayout, Accordion, AccordionToggle, AccordionContent, Box, Link, Typography, Field, Flex, Tabs, Tab, TabGroup, TabPanels, TabPanel, Grid, GridItem,
  RadioGroup,
  Checkbox, Status, Loader} from "@strapi/design-system";

import { ArrowLeft, ExternalLink } from "@strapi/icons";

import Markdown from 'react-markdown'
import '../markdown.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function ImageDialog(props) {
  const { open, handleClose, image } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <img src={image} alt="" />
    </Dialog>
  );
}

const ClaimDetails = () => {
  const { urlkey } = useParams();
  const user = useContext(GlobalContextState).user;
  const uP = user.subscriptionPlan;
  const token = useContext(GlobalContextState).token;
  const [onlyAdas, setOnlyAdas] = useState(true);
  const [initialSelectedTabIndex, setInitialSelectedTabIndex] = useState(0);
  const [claimData, setClaimData] = useState([]);
  const [titleHeading, setTitleHeading] = useState("");
  const [reportPartsAvailable, setReportPartsAvailable] = useState([]);
  const [reportDataOpLines, setReportDataOpLines] = useState([]);
  const [s3Data, setS3Data] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState();
  const [imageOpen, setImageOpen] = useState(false);
  const [image, setImage] = useState("");
  const [logData, setLogData] = useState([]);
  const [originLogData, setOriginLogData] = useState([]);
  const [isLogLoading, setIsLogLoading] = useState(true);
  const [processLogsData, setProcessLogsData] = useState([]);
  const [isProcessLogsLoading, setIsProcessLogsLoading] = useState(true);
  const [isBmsLoading, setIsBmsLoading] = useState(true);
  const [reportData, setReportData] = useState({});
  const [isReportLoading, setIsReportLoading] = useState(true);
  const [colorMapping, setColorMapping] = useState({});
  const [deltaReportData, setDeltaReportData] = useState({});
  const [isDeltaReportLoading, setIsDeltaReportLoading] = useState(true);
  const [adasReportData, setAdasReportData] = useState({});
  const [isAdasReportLoading, setIsAdasReportLoading] = useState(true);
  const [deltaReportDetailExpanded, setDeltaReportDetailExpanded] = useState(false);
  const [claimTrail, setClaimTrail] = useState([]);
  const [opLinesDiff, setOpLinesDiff] = useState([]);
  const [dataHumanBms, setDataHumanBms] = useState(null);
  const [dataDiffBms, setDataDiffBms] = useState(null);
  const [dataTcBms, setDataTcBms] = useState(null);
  const [messStep, setMessStep] = useState(0);
  const [messClass, setMessClass] = useState('');
  const [adasStatus, setAdasStatus] = useState('pending');
  const [estimatePdfLink, setEstimatePdfLink] = useState(null);

  const [totalDiffBmsAdded, setTotalDiffBmsAdded] = useState(null);
  const [totalDiffBmsDeleted, setTotalDiffBmsDeleted] = useState(null);
  const [totalDiffBmsMatched, setTotalDiffBmsMatched] = useState(null);
  const [totalDiffBmsModified, setTotalDiffBmsModified] = useState(null);
  
  const [expandedDiffAdded, setExpandedDiffAdded] = useState(false);
  const [expandedDiffMatched, setExpandedDiffMatched] = useState(false);
  const [expandedDiffDeleted, setExpandedDiffDeleted] = useState(false);
  const [expandedDiffModified, setExpandedDiffModified] = useState(false);

  const [hasChatData, setHasChatData] = useState(false);
  const [describeDamage, setDescribeDamage] = useState('');
  const [airbagsDeployed, setAirbagsDeployed] = useState('');
  const [steeringWheelDamaged, setSteeringWheelDamaged] = useState('');
  const [carDrivable, setCarDrivable] = useState('');
  const [impactOccur, setImpactOccur] = useState('');
  const [partsAreDamaged, setPartsAreDamaged] = useState('');
  const [vehicleMileage, setVehicleMileage] = useState('');
  const [vehicleVin, setVehicleVin] = useState('');
  const [claimUser, setClaimUser] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [showTrail, setShowTrail] = useState(false);
  const dropdownRef = useRef(null);

  const tabGroupRef = React.useRef();
  
  const [availablePoi, setAvailablePoi] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [expandedTcLineDamage, setExpandedTcLineDamage] = useState(false);
  const [expandedTcPdf, setExpandedTcPdf] = useState(false);
  const [imagesOpline, setImagesOpline] = useState([]);
  const [selectedImageOpline, setSelectedImageOpline] = useState(null);
  const [positionStatement, setPositionStatement] = useState(null);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [customOperation, setCustomOperation] = useState({});
  const [checkedDamages, setCheckedDamages] = useState({
    'all': true,
    'original': true,
    'dent': true,
    'parts': false,
    'scratch': true,
    'tear': true,
  });

  const [diffSetting, setDiffSetting] = useState({
    'all': false,
    'remove-replace': true,
    'blend': true,
    'repair': true,
  });

  const updateDiffSetting = (index, value) => {
    setDiffSetting({ ...diffSetting, [index]: value });
  };

  const [open, setOpen] = React.useState(false);
  const [flagPos, setFlagPos] = React.useState('top');

  const [messages, setMessages] = useState([]);

  const [selectedPoi, setSelectedPoi] = useState({
    'Front': false,
    'Front_Left': false,
    'Front_Right': false,
    'Rear': false,
    'Rear_Left': false,
    'Rear_Right': false,
    'Left': false,
    'Right': false,
  });

  const [poiDefaultSelected, setPoiDefaultSelected] = useState({
    'Front': false,
    'Front_Left': false,
    'Front_Right': false,
    'Rear': false,
    'Rear_Left': false,
    'Rear_Right': false,
    'Left': false,
    'Right': false,
  });
  

  const [defaultDamagePoi, setDefaultDamagePoi] = useState({
    'Front': false,
    'Front_Left': false,
    'Front_Right': false,
    'Rear': false,
    'Rear_Left': false,
    'Rear_Right': false,
    'Left': false,
    'Right': false,
  });

  // RGB color map for the segments. Generate random colors for each segment.
  const COLORS = [
    { r: 166, g: 206, b: 227 },
    { r: 31, g: 120, b: 180 },
    { r: 178, g: 223, b: 138 },
    { r: 51, g: 160, b: 44 },
    { r: 251, g: 154, b: 153 },
    { r: 227, g: 26, b: 28 },
    { r: 253, g: 191, b: 111 },
    { r: 255, g: 127, b: 0 },
    { r: 202, g: 178, b: 214 },
    { r: 106, g: 61, b: 154 },
    { r: 255, g: 255, b: 153 },
    { r: 177, g: 89, b: 40 },
  ];

  const [title, setTitle] = useState('');

  const fetchTitle = async () => {
    const fetchOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${baseUrl}/api/tc-app/get-claim/${urlkey}`;
    const response = await fetch(url, fetchOptions);
    const claims = await response.json();
    const claim = claims[0];
    const title = claim.vehicleName && claim.vehicleName !== '0 null null' ? claim.vehicleName : 'No vehicle selected';
    document.title = title;
    setTitle(title);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains('tc-trail-action')
      ) {
        setShowTrail(false);
      }
    };

    // Add event listener to the document on mount
    document.addEventListener("mousedown", handleClickOutside);
    
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Get url param tab 
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if(tab === 'adas' || tab === 'rev'){
      setInitialSelectedTabIndex(2);
    }
    // Initial fetch
    fetchTitle();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Refetch or update when the tab becomes active
        fetchTitle();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if(reportData && Object.keys(reportData).length !== 0 && reportData.status !== 'failed' && reportPartsAvailable.length > 0){
      const listPartsReport = [];
      Object.keys(reportData.reportJson).map((key, index) => {
        listPartsReport.push(key);
      });
      // Get the list in the reportPartsAvailable but not in the listPartsReport
      let opLinesDiff = [];
      const difference = reportPartsAvailable.filter(x => !listPartsReport.includes(x));
      if(difference.length > 0 && s3Data.opLines){
        s3Data.opLines.map((opLine, index) => {
          if(difference.includes(opLine.realPart)){
            opLinesDiff.push(opLine);
          }
        });
      }

      if (opLinesDiff.length > 0) {
        setOpLinesDiff(opLinesDiff);
      }
    }
    
  }, [reportData, reportPartsAvailable]);

  const handleImageClick = (image) => {
    setImage(image);
    setImageOpen(true);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };


  function toggleDrawer(newOpen, newFlagPos = 'top') {
    if(flagPos !== newFlagPos){
      // Empty text area #flagMessage
      const element = document.getElementById('flagMessage');
      if(element){
        element.value = '';
      }
    }
    setFlagPos(newFlagPos);
    setOpen(newOpen);
  };

  function handleClickPoi(index) {
    const element = document.querySelector(`.tc-poi-pie-item[data-position="${index}"]`);
    if(element){
      if(selectedPoi[index]){
        setSelectedPoi({ ...selectedPoi, [index]: false });
      }else {
        setSelectedPoi({ ...selectedPoi, [index]: true });
      }
    }
  }

  function updateLogData(selectedImage){
    let newLogData = [];
    originLogData.map((log, index) => {
      if(log.image == selectedImage){
        newLogData.push(log);
      }
    });
    setLogData(newLogData);
  }

  function formatDateData(date){
    let formatted = new Date(date).toLocaleString("en-US", {
      timeZone: "Canada/Eastern",
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formatted;
  }

  function getSecondaryAction(claimData) {
    if(claimData && claimData.mitchellUrlKey) {
      return (
        <div className="top-actions flex">
          <a target="_blank" href={`https://repaircenterca.mymitchell.com/connect/#/job-overview/${claimData.mitchellUrlKey}`} className={`view-mitchell ${onlyAdas ? 'disabled' : ''}`}><span>M<ExternalLink /></span></a>
          <button className="flag-actions" onClick={() => toggleDrawer(true, 'top')}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#fff" height="20px" width="20px" version="1.1" id="Capa_1" viewBox="0 0 489 489" xmlSpace="preserve">
              <g>
                <g>
                  <path d="M454.3,31.6c-28.5-15.3-59.1-23.4-93.7-23.4c-40.7,0-81.5,11.2-120.2,21.4S166,50,130.4,50c-23.8,0-45.3-4.6-65.2-13.7    V20.4C65.2,9.2,56,0,44.8,0S24.4,9.2,24.4,20.4v448.2c0,11.2,9.2,20.4,20.4,20.4s20.4-9.2,20.4-20.4v-148    c20,6.9,41.2,10.5,64.2,10.5c40.7,0,81.5-11.2,120.2-20.4c38.7-10.2,74.4-20.4,110-20.4c27.5,0,52,6.1,74.4,18.3    c12.7,8.7,30.6-2.1,30.6-17.3V49.9C464.4,41.8,460.4,35.7,454.3,31.6z M423.7,258.8c-20.4-7.1-41.8-10.2-64.2-10.2    c-40.7,0-81.5,11.2-120.2,21.4s-74.4,20.4-110,20.4c-23.4,0-44.8-4.1-64.2-13.2V79.5c20.4,7.1,41.8,10.2,64.2,10.2    c40.7,0,81.5-11.2,120.2-21.4s74.4-20.4,110-20.4c23.4,0,44.8,4.1,64.2,13.2V258.8z"/>
                </g>
              </g>
            </svg>
          </button>
        </div>
      )
    }
    if(claimData && claimData.estimatic === 'ccc') {
      return (
        <div className="view-mitchell">
          <span>
            C
          </span>
        </div>
      )
    }

    return null;
  }


  function updateCheckedDamages(index, value) {
    if(index === 'all'){
      let copyCheckedDamages = { ...checkedDamages };
      copyCheckedDamages['all'] = value;
      copyCheckedDamages['dent'] = value;
      copyCheckedDamages['scratch'] = value;
      copyCheckedDamages['tear'] = value;

      setCheckedDamages(copyCheckedDamages);
    }else {
      setCheckedDamages({ ...checkedDamages, [index]: value });
    }
  }

  function onDocumentLoadSuccess(pdf) {
    const numPages = pdf.numPages;
    setNumPages(numPages);
  }

  function makeOnAllPagesRenderedCallback(onAllPagesRendered) {
    const pages = [];
  
    function inputRef(pageComponent) {
      if (!pageComponent) {
        return;
      }

      // Get page number from page component
      const pageNumber = pageComponent.getAttribute("data-page-number");
  
      // Register Page component at mount
      pages[pageNumber] = false;
    }
  
    function onRenderSuccess(page) {
      // Register page as rendered
      pages[page.pageNumber] = true;
  
      // If all pages registered are now rendered, call the callback
      if (pages.every(Boolean)) {
        onAllPagesRendered();
      }
    }
  
    return {
      inputRef,
      onRenderSuccess,
    };
  }

  function onAllPagesRendered() {
    hidePIIFromPDF()
  }

  const additionalProps = makeOnAllPagesRenderedCallback(onAllPagesRendered);

  function hidePIIFromPDF() {
    // Find in pdfDiv a span with attribute role="presentation"
    const presentationSpans = document.querySelectorAll(
      'span[role="presentation"]'
    );
    // Loop through all span and find all the spans that contain the word "LABOR" or "MAIN-D'ŒUVRE"
    const laborSpans = Array.from(presentationSpans).filter((span) =>
      span.textContent.includes("LABOR") || span.textContent.includes("MAIN-D'ŒUVRE")
    );
    
    if(laborSpans.length > 0) {
      const laborSpan = laborSpans[0]
      // Find the pdf div by class tc-human-estimate
      const pdfDiv = document.querySelector(".tc-human-estimate");
      // Find current page number by finding the parent of the labor span with class "react-pdf__Page"
      const currentPage = laborSpan.closest(".react-pdf__Page")
      const currentPageNumber = currentPage.getAttribute("data-page-number");
      // Hide all page before the current page
      for (let i = 1; i < currentPageNumber; i++) {
        const page = pdfDiv.querySelector(`div.react-pdf__Page[data-page-number="${i}"]`);
        page.style.display = "none";
      }
      // Find position of the labor span
      const laborSpanPosition = laborSpan.getBoundingClientRect();
      // Calculate distance from top of currentPage to laborSpan
      const distanceFromTop = laborSpanPosition.top - currentPage.getBoundingClientRect().top;
      
      // Add style overflow-y: hidden to the pdfDiv
      pdfDiv.style.overflowY = "hidden";
      // Add minus margin-top to current page to show the labor span at the top
      currentPage.style.marginTop = `-${distanceFromTop}px`;
    }
  }

  function formatHtml(html) {
    if(!html){
      return '';
    }
    // Replace \n with <br> and show the html
    let replace = html.replace(/\n/g, "<br>");
    return parse(replace);
  }

  const customCss = `<style>
  .tc-estimate-col body {
    background: unset;
    font-family: unset;
    color: #000;
  }
  </style>`;

  const fetchClaimData = async () => {
    if (isLoading === false) setIsLoading(true);

    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${baseUrl}/api/tc-app/get-claim/${urlkey}`;
      const response = await fetch(url, fetchOptions);
      const claims = await response.json();
      const claim = claims[0];
      
      setClaimData(claim);

      let vehicleBrand = '';
      if(claim.vehicleMake){
        vehicleBrand = claim.vehicleMake;
      }else if(claim.vehicleName){
        const vehicleName = claim.vehicleName;
        const parts = vehicleName.split(" ");
        vehicleBrand = parts[1];
      }
      
      if(vehicleBrand !== ''){
        vehicleBrand = vehicleBrand.toLowerCase().replace(/\s/g, '');
        await fetchPositionStatement(vehicleBrand)
      }
      setAdasStatus(claim.adasStatus);

      if(user.superAdmin){
        const claimUser = claim.user.company ? claim.user.company : "N/A"
        let clUser = ` - Garage: ` + claimUser;
        if(claim.user.username){
          clUser += ` - User: ` + claim.user.mitchellUsername;
        }
        if(claim.user.mitchellId){
          clUser += ` - MITID: ` + claim.user.mitchellId;
        }
        setClaimUser(clUser);
      }

      const mitchellUrlKey = claim.mitchellUrlKey;
      if(mitchellUrlKey){
        setOnlyAdas(false);
        if(claim.ownerMsgSent){
          setMessClass('owner-msg-sent');
        }

        await fetchBmsData(claim);
        await fetchS3Data(mitchellUrlKey);
        await fetchLogData(mitchellUrlKey);
        await fetchReportData(mitchellUrlKey);
        await fetchDeltaReportData(mitchellUrlKey);
        await fectChatData(mitchellUrlKey);
        await fetchTcClaimTrailData(mitchellUrlKey);
        await fetchProcessLogsData(mitchellUrlKey);
      } else {
        if(claim.creationData && claim.creationData.mode === 'manual'){
          setVehicleVin(' - VIN: ' + claim.vehicleVin);
          const exportTo = JSON.parse(claim.creationData.exports);
          if(exportTo && exportTo.mitchell){
            if(claim.creationStatus && claim.creationStatus === 'processing'){
              setIsCreating(true);
            }
          }
        } else {
          setInitialSelectedTabIndex(2);
          fetchAdasReportData(claim.tcUrlKey);
          fetchEstimatePdfLink(claim.tcUrlKey);
          if(claim.extractedPdfData) {
            if(claim.extractedPdfData.insurance_company){
              setTitleHeading(`(${claim.extractedPdfData.insurance_company})`);
            }
            if(claim.extractedPdfData.vin){
              setVehicleVin(' - VIN: ' + claim.extractedPdfData.vin);
            }
            if(claim.extractedPdfData.odometer){
              setVehicleMileage(' - Vehicle\'s mileage: ' + claim.extractedPdfData.odometer + ` km`);
            } else {
              setVehicleMileage(' - Vehicle\'s mileage: N/A');
            }
          }
        }
      }

      fetchCustomReportData(claim.tcUrlKey);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fectChatData = async (mitchellUrlKey) => {
    if (isLoading === false) setIsLoading(true);
    try {
      const fetchChatOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const urlChat = `${baseUrl}/api/chatbot/get-chat-history/${mitchellUrlKey}`;
      const responseChat = await fetch(urlChat, fetchChatOptions);
      const chatData = await responseChat.json();
      if (chatData && chatData.messages && chatData.messages.length > 0) {
        setMessages(chatData.messages);

        const messages = chatData.messages;
        let totalStep = 0;
        messages.forEach( (message) => {
          if (message.action === "upload-image" && message.type === "image") {
            totalStep++;
            setMessClass('owner-msg-sent');
          }
          if (message.action === "confirm-airbags") {
            totalStep++;
            setMessClass('owner-msg-sent');
          }
          if (message.action === "confirm-steering") {
            totalStep++;
            setMessClass('owner-msg-sent');
          }
          if (message.action === "confirm-mileage") {
            totalStep++;
            setMessClass('owner-msg-sent');
          }
          if (message.action === "confirm-vin") {
            totalStep++;
            setMessClass('owner-msg-sent');
          }
          if (message.action === "confirm-pois") {
            totalStep++;
            setMessClass('owner-msg-sent');
          }
          if (message.action === "confirm-parts") {
            totalStep++;
            setMessClass('owner-msg-sent');
          }
          if(message.action === 'session-end'){
            totalStep++;
          }
        });
        setMessStep(totalStep);
        if(totalStep === 8){
          setMessClass('owner-msg-completed');
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchPositionStatement = async (vehicleBrand) => {
    const fetchOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let url = `${baseUrl}/api/tc-app/get-position-statement?brand=${vehicleBrand}`;
    const response = await fetch(url, fetchOptions);
    const positionStatement = await response.json();
    setPositionStatement(positionStatement);
  }

  const fetchS3Data = async (mitchellUrlKey) => {
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let url = `${baseUrl}/api/tc-app/get-s3-folder-data/${mitchellUrlKey}`;
      
      const response = await fetch(url, fetchOptions);
      const s3Data = await response.json();
      console.log(s3Data);
      if(s3Data.data.insurance && s3Data.data.insurance.claim){
        let tt = s3Data.data.insurance.claim.insuranceCompany ? "(" + s3Data.data.insurance.claim.insuranceCompany + ")" : " ";
        setTitleHeading(tt);
      }

      if(s3Data.data.claimInfo && s3Data.data.claimInfo.overview && s3Data.data.claimInfo.overview.vehicle && s3Data.data.claimInfo.overview.vehicle.vin){
        const vehicleVin = ' - VIN: ' + s3Data.data.claimInfo.overview.vehicle.vin;
        setVehicleVin(vehicleVin);
      }

      setS3Data(s3Data);
      
      if(s3Data.mileage){
        const vehicleMileage = ' - Vehicle\'s mileage: ' + s3Data.mileage + ` km`;
        setVehicleMileage(vehicleMileage);
      }

      if(s3Data.chatAnswers){
        if(s3Data.chatAnswers.airbagsDeployed !== '' && s3Data.chatAnswers.airbagsDeployed !== undefined){
          setAirbagsDeployed(s3Data.chatAnswers.airbagsDeployed);
          setHasChatData(true);
        }

        if(s3Data.chatAnswers.steeringWheelDamaged !== '' && s3Data.chatAnswers.steeringWheelDamaged !== undefined){
          setSteeringWheelDamaged(s3Data.chatAnswers.steeringWheelDamaged);
          setHasChatData(true);
        }

        if(s3Data.chatAnswers.damageDescription !== '' && s3Data.chatAnswers.damageDescription !== undefined){
          setDescribeDamage(s3Data.chatAnswers.damageDescription);
          setHasChatData(true);
        }

        if(s3Data.chatAnswers.drivable !== '' && s3Data.chatAnswers.drivable !== undefined){
          setCarDrivable(s3Data.chatAnswers.drivable);
          setHasChatData(true);
        }

        if(s3Data.chatAnswers.pois !== '' && s3Data.chatAnswers.pois !== undefined){
          setImpactOccur(s3Data.chatAnswers.pois);
          setHasChatData(true);
        }

        if(s3Data.chatAnswers.parts !== '' && s3Data.chatAnswers.parts !== undefined){
          setPartsAreDamaged(s3Data.chatAnswers.parts);
          setHasChatData(true);
        }
      }

      /* Report & OpLines */
      if(s3Data.opLines && s3Data.opLines.length > 0){
        let partAvailable = [];
        let reportDataOpLines = [];
        s3Data.opLines.map((opLine, index) => {
          partAvailable.push(opLine.realPart);
          reportDataOpLines[opLine.realPart] = opLine;
        });
        setReportPartsAvailable(partAvailable);
        setReportDataOpLines(reportDataOpLines);
      }

      let imagesData = [];
      if (s3Data.resultImages) {
        if(s3Data.resultImages.length != 0){
          Object.keys(s3Data.resultImages).map((part, indexPart) => {
            s3Data.resultImages[part].map((images, index) => [
              Object.keys(images).map((key, index) => {
                if (key === 'name' && images['name']) {
                  imagesData.push({
                    url: images['original'],
                    name: images['name'],
                    masks: images['masks'],
                    index_position: part,
                  });
                }
              })
            ])
          });
        }
      }
      setImagesOpline(imagesData);
      setSelectedImageOpline(imagesData[0]);
      /* End Report & OpLines */

      let avlPoi = [];
      if (s3Data.resultImages) {
        if(s3Data.resultImages.length != 0){
          Object.keys(s3Data.resultImages).map((part, indexPart) => {
            // If part is not in the avlPoi array, push it
            if(!avlPoi.includes(part)){
              avlPoi.push(part);
            }
          })
        }
        setAvailablePoi(avlPoi);

        
        if (s3Data.data.claimInfo){
          let listPOI = [];
          let primaryPoi = s3Data.data.claimInfo.overview.vehicle.status.primaryPointOfImpact;
          let secondaryPoi = s3Data.data.claimInfo.overview.vehicle.status.secondaryPointsOfImpact;
          // Push primaryPoi to listPOI
          if(primaryPoi){
            listPOI.push(primaryPoi);
          }
          if(secondaryPoi){
            // loop through secondaryPointsOfImpact and push it to listPOI
            secondaryPoi.map((poi) => {
              listPOI.push(poi);
            })
          }

          let defaultDamage = {
            'Front': false,
            'Front_Left': false,
            'Front_Right': false,
            'Rear': false,
            'Rear_Left': false,
            'Rear_Right': false,
            'Left': false,
            'Right': false,
          };
          // Loop through listPOI and set selectedPoi and defaultDamagePoi
          listPOI.map((selectedPoi) => {
            switch(selectedPoi){
              case 'Front':
                defaultDamage['Front'] = true;
                break;
              case 'LeftSide':
                defaultDamage['Front'] = true;
                break;
              case 'LeftFrontSide':
                defaultDamage['Front_Left'] = true;
                break;
              case 'LeftFrontCorner':
                defaultDamage['Front_Left'] = true;
                break;
              case 'RightFrontCorner':
                defaultDamage['Front_Right'] = true;
                break;
              case 'RightFrontFender':
                defaultDamage['Front_Right'] = true;
                break;
              case 'RightSide':
                defaultDamage['Right'] = true;
                break;
              case 'RightRearSide':
                defaultDamage['Rear_Right'] = true;
                break;
              case 'RightRearCorner':
                defaultDamage['Rear_Right'] = true;
                break;
              case 'Rear':
                defaultDamage['Rear'] = true;
                break;
              case 'LeftRearCorner':
                defaultDamage['Rear_Left'] = true;
                break;
              case 'LeftRearSide':
                defaultDamage['Rear_Left'] = true;
                break;
            }
          });

          if (listPOI.length === 0 && s3Data.poisWithParts && s3Data.poisWithParts.length > 0){
            // s3Data.data.poisWithParts is an array of objects. Each object key is the POI and the value is an array of parts
            // if poisWithParts has data, use it to set the defaultDamage instead of the defaultDamage set above
            defaultDamage = {
              'Front': false,
              'Front_Left': false,
              'Front_Right': false,
              'Rear': false,
              'Rear_Left': false,
              'Rear_Right': false,
              'Left': false,
              'Right': false,
            };
            s3Data.poisWithParts.map((poi) => {
              let poiKey = Object.keys(poi)[0];
              switch(poiKey){
                case 'Front':
                  defaultDamage['Front'] = true;
                  break;
                case 'L-Front-Corner':
                  defaultDamage['Front_Left'] = true;
                  break;
                case 'L-Front-Side':
                  defaultDamage['Left'] = true;
                  break;
                case 'L-Side':
                  defaultDamage['Left'] = true;
                  break;
                case 'L-Rear-Side':
                  defaultDamage['Rear_Left'] = true;
                  break;
                case 'L-Rear-Corner':
                  defaultDamage['Rear_Left'] = true;
                  break;
                case 'Rear':
                  defaultDamage['Rear'] = true;
                  break;
                case 'R-Rear-Corner':
                  defaultDamage['Rear_Right'] = true;
                  break;
                case 'R-Rear-Side':
                  defaultDamage['Rear_Right'] = true;
                  break;
                case 'R-Side':
                  defaultDamage['Right'] = true;
                  break;
                case 'R-Front-Side':
                  defaultDamage['Right'] = true;
                  break;
                case 'R-Front-Corner':
                  defaultDamage['Front_Right'] = true;
                  break;
              }
            });
          }

          // Consider poisFromDamageDescription if poisWithParts and listPOI are empty
          if (listPOI.length === 0 && s3Data.poisWithParts.length === 0 && s3Data.poisFromDamageDescription && s3Data.poisFromDamageDescription.length > 0){
            // s3Data.data.poisFromDamageDescription is an array of pois. Example: ['Front', 'Rear']
            // if poisFromDamageDescription has data, use it to set the defaultDamage instead of the defaultDamage set above
            defaultDamage = {
              'Front': false,
              'Front_Left': false,
              'Front_Right': false,
              'Rear': false,
              'Rear_Left': false,
              'Rear_Right': false,
              'Left': false,
              'Right': false,
            };
            s3Data.poisFromDamageDescription.map((poi) => {
              switch(poi){
                case 'Front':
                  defaultDamage['Front'] = true;
                  break;
                case 'L-Front-Corner':
                  defaultDamage['Front_Left'] = true;
                  break;
                case 'L-Front-Side':
                  defaultDamage['Left'] = true;
                  break;
                case 'L-Side':
                  defaultDamage['Left'] = true;
                  break;
                case 'L-Rear-Side':
                  defaultDamage['Rear_Left'] = true;
                  break;
                case 'L-Rear-Corner':
                  defaultDamage['Rear_Left'] = true;
                  break;
                case 'Rear':
                  defaultDamage['Rear'] = true;
                  break;
                case 'R-Rear-Corner':
                  defaultDamage['Rear_Right'] = true;
                  break;
                case 'R-Rear-Side':
                  defaultDamage['Rear_Right'] = true;
                  break;
                case 'R-Side':
                  defaultDamage['Right'] = true;
                  break;
                case 'R-Front-Side':
                  defaultDamage['Right'] = true;
                  break;
                case 'R-Front-Corner':
                  defaultDamage['Front_Right'] = true;
                  break;
              }
            });
          }

          let newSelectedPoi = {};
          Object.keys(defaultDamage).map((index, poi) => {
            if(!avlPoi.includes(index)){
              newSelectedPoi[index] = false;
            }else {
              newSelectedPoi[index] = defaultDamage[index];
            }
          });

          setDefaultDamagePoi(newSelectedPoi);
          setSelectedPoi(newSelectedPoi);
          setPoiDefaultSelected(defaultDamage);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLogData = async (mitchellUrlKey) => {
    if (isLogLoading === false) setIsLogLoading(true);
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${baseUrl}/api/tc-app/get-log/${mitchellUrlKey}`;
      const response = await fetch(url, fetchOptions);
      const logData = await response.json();
      if (logData.length > 0) {
        setOriginLogData(logData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLogLoading(false);
    }
  };

  const fetchProcessLogsData = async (mitchellUrlKey) => {
    if (isProcessLogsLoading === false) setIsProcessLogsLoading(true);
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${baseUrl}/api/tc-app/get-process-logs/${mitchellUrlKey}`;
      const response = await fetch(url, fetchOptions);
      const logData = await response.json();
      if (logData.length > 0) {
        setProcessLogsData(logData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessLogsLoading(false);
    }
  };

  const fetchReportData = async (mitchellUrlKey) => {
    if (isReportLoading === false) setIsReportLoading(true);
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${baseUrl}/api/tc-app/get-report/${mitchellUrlKey}`;
      const response = await fetch(url, fetchOptions);
      const reportData = await response.json();
      setReportData(reportData);
      if (reportData.status === "success") {
        setColorMapping(getColorMapping(reportData.reportColor));
      }

    } catch (error) {
      console.log(error);
    } finally {
      setIsReportLoading(false);
    }
  };

  const fetchDeltaReportData = async (mitchellUrlKey) => {
    if (isDeltaReportLoading === false) setIsDeltaReportLoading(true);
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${baseUrl}/api/tc-app/get-delta-report/${mitchellUrlKey}`;
      const response = await fetch(url, fetchOptions);
      const reportData = await response.json();
      if (reportData.status !== "success") {
        setDeltaReportData({});
        return
      }
      setDeltaReportData(reportData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeltaReportLoading(false);
    }
  };

  const fetchAdasReportData = async (tcUrlKey) => {
    if (isAdasReportLoading === false) setIsAdasReportLoading(true);
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${baseUrl}/api/tc-app/get-adas-report/${tcUrlKey}`;
      const response = await fetch(url, fetchOptions);
      const reportData = await response.json();
      setAdasReportData(reportData);
      setAdasStatus('completed');
    } catch (error) {
      console.log(error);
    } finally {
      setIsAdasReportLoading(false);
    }
  };

  const fetchCustomReportData = async (tcUrlKey) => {
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${baseUrl}/api/tc-app/get-custom-report/${tcUrlKey}`;
      const response = await fetch(url, fetchOptions);
      const reportData = await response.json();
      setCustomOperation(reportData);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchEstimatePdfLink = async (mitchellUrlKey) => {
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${baseUrl}/api/tc-app/get-est-pdf/${mitchellUrlKey}`;
      const response = await fetch(url, fetchOptions);
      const responseData = await response.json();
      if (responseData.status === "success") {
        setEstimatePdfLink(responseData.link);
      }
    } catch (error) {
      console.log(error);
    } finally {
      //setIsAdasReportLoading(false);
    }
  };

  const fetchBmsData = async (claim) => {
    if (isBmsLoading === false) setIsBmsLoading(true);
    try {
      const mitchellData = {
        mitchellUrlKey: claim.mitchellUrlKey,
        mitchellId: claim.user.mitchellId
      }

      const url = `${baseUrl}/api/tc-app/get-bms-diff`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(mitchellData),
      });
      const data = await response.json();

      if(data.dataTcBms && data.dataTcBms.length > 0){
        setDataTcBms(data.dataTcBms);
      }
      if(data.dataHumanBms && data.dataHumanBms.length > 0){
        setDataHumanBms(data.dataHumanBms);
      }
      if(data.dataDiffBms && data.dataDiffBms.length > 0){
        setDataDiffBms(data.dataDiffBms);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setIsBmsLoading(false);
    }
  };

  useEffect(() => {
    if(!diffSetting["remove-replace"] && !diffSetting["blend"] && !diffSetting["repair"] && !diffSetting["all"]){
      setDiffSetting({ ...diffSetting, 'all': true });
    }
    if(dataDiffBms && dataDiffBms.length > 0){
      const allDataDiffBms = dataDiffBms;
      let bmsDiffMatch = {
        'title': 'Matched',
        'PartPrice': 0,
        'LaborHours': 0,
        'allData': []
      }
      let bmsDiffDeleted = {
        'title': 'Deleted',
        'PartPrice': 0,
        'LaborHours': 0,
        'allData': []
      };
      let bmsDiffAdded = {
        'title': 'Added',
        'PartPrice': 0,
        'LaborHours': 0,
        'allData': []
      };
      let bmsDiffModified = {
        'title': 'Modified',
        'lPartPrice': 0,
        'lLaborHours': 0,
        'rPartPrice': 0,
        'rLaborHours': 0,
        'allData': []
      }

      allDataDiffBms.map((diffBms, index) => {
        const lLaborOperation = diffBms.lLaborOperation ? diffBms.lLaborOperation : '';
        const rLaborOperation = diffBms.rLaborOperation ? diffBms.rLaborOperation : '';
        const canDisplay = canDisplayDiff(lLaborOperation, rLaborOperation);
        if(canDisplay){
          if(diffBms.status === 'matched'){
            bmsDiffMatch['allData'].push(diffBms);
            if(diffBms.lPartPrice){
              const linePrice = parseFloat(diffBms.lPartPrice);
              bmsDiffMatch['PartPrice'] += linePrice;
            }
    
            if(diffBms.lLaborHours){
              const lineLaborHours = parseFloat(diffBms.lLaborHours);
              bmsDiffMatch['LaborHours'] += lineLaborHours;
            }
          }
    
          if(diffBms.status === 'deleted'){
            bmsDiffDeleted['allData'].push(diffBms);
            if(diffBms.lPartPrice){
              const linePrice = parseFloat(diffBms.lPartPrice);
              bmsDiffDeleted['PartPrice'] += linePrice;
            }
    
            if(diffBms.lLaborHours){
              const lineLaborHours = parseFloat(diffBms.lLaborHours);
              bmsDiffDeleted['LaborHours'] += lineLaborHours;
            }
          }
    
          if(diffBms.status === 'added'){
            bmsDiffAdded['allData'].push(diffBms);
            
            if(diffBms.rPartPrice){
              const linePrice = parseFloat(diffBms.rPartPrice);
              bmsDiffAdded['PartPrice'] += linePrice;
            }
    
            if(diffBms.rLaborHours){
              const lineLaborHours = parseFloat(diffBms.rLaborHours);
              bmsDiffAdded['LaborHours'] += lineLaborHours;
            }
          }
    
          if(diffBms.status === 'modified'){
            bmsDiffModified['allData'].push(diffBms);
            if(diffBms.lPartPrice){
              const llinePrice = parseFloat(diffBms.lPartPrice);
              bmsDiffModified['lPartPrice'] += llinePrice;
            }
    
            if(diffBms.lLaborHours){
              const llineLaborHours = parseFloat(diffBms.lLaborHours);
              bmsDiffModified['lLaborHours'] += llineLaborHours;
            }
    
            if(diffBms.rPartPrice){
              const rlinePrice = parseFloat(diffBms.rPartPrice);
              bmsDiffModified['rPartPrice'] += rlinePrice;
            }
    
            if(diffBms.rLaborHours){
              const rlineLaborHours = parseFloat(diffBms.rLaborHours);
              bmsDiffModified['rLaborHours'] += rlineLaborHours;
            }
          }
        }
      });

      let titleMatched = 'Matched (' + bmsDiffMatch['allData'].length + ' parts - $' + bmsDiffMatch['PartPrice'].toFixed(2) + ' - ' + bmsDiffMatch['LaborHours'].toFixed(1) + ' hours)';
      bmsDiffMatch['title'] = titleMatched;

      let titleDeleted = 'Deleted (' + bmsDiffDeleted['allData'].length + ' parts - $' + bmsDiffDeleted['PartPrice'].toFixed(2) + ' - ' + bmsDiffDeleted['LaborHours'].toFixed(1) + ' hours)';
      bmsDiffDeleted['title'] = titleDeleted;

      let titleAdded = 'Added (' + bmsDiffAdded['allData'].length + ' parts - $' + bmsDiffAdded['PartPrice'].toFixed(2) + ' - ' + bmsDiffAdded['LaborHours'].toFixed(1) + ' hours)';
      bmsDiffAdded['title'] = titleAdded;

      let titleModified = 'Modified (' + bmsDiffModified['allData'].length + ' parts - From $' + bmsDiffModified['lPartPrice'].toFixed(2) + ' to $' + bmsDiffModified['rPartPrice'].toFixed(2) + ' - From ' + bmsDiffModified['lLaborHours'].toFixed(1) + ' hours to ' + bmsDiffModified['rLaborHours'].toFixed(1) + ' hours)';
      bmsDiffModified['title'] = titleModified;

      setTotalDiffBmsAdded(bmsDiffAdded);
      setTotalDiffBmsDeleted(bmsDiffDeleted);
      setTotalDiffBmsMatched(bmsDiffMatch);
      setTotalDiffBmsModified(bmsDiffModified); 
    }
  }, [dataDiffBms, diffSetting]);

  const canDisplayDiff = (lLaborOperation, rLaborOperation) => {
    if(diffSetting["remove-replace"] && (lLaborOperation === 'OP11' || rLaborOperation === 'OP11')){
      return true;
    }
    if(diffSetting["repair"] && (lLaborOperation === 'OP9' || rLaborOperation === 'OP9')){
      return true;
    }
    if(diffSetting["blend"] && (lLaborOperation === 'OP15' || rLaborOperation === 'OP15')){
      return true;
    }
    if(diffSetting["all"]){
      return true;
    }

    return false;
  }

  const fetchTcClaimTrailData = async (mitchellUrlKey) => {
    if (isLogLoading === false) setIsLogLoading(true);
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${baseUrl}/api/tc-app/get-claim-trail/${mitchellUrlKey}`;
      const response = await fetch(url, fetchOptions);
      const claimTrailData = await response.json();
      if (claimTrailData.length > 0) {
        setClaimTrail(claimTrailData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLogLoading(false);
    }
  }

  const getLogImageSrc = (image, s3images) => {
    if (s3images) {
      // Find the image in the s3images object that matches the image name
      let imageSrc = '';
      for (const [key, value] of Object.entries(s3images)) {
        value.map((s3image) => {
          if (s3image.name === image) {
            imageSrc = s3image.original;
          }
        });
      }
      return imageSrc;
    }
  };

  const getColorMapping = (reportColor) => {
    const values = Object.values(reportColor);
    const max = Math.max(...values);
    const colorPointMapping = {
      1: "text-green-700",
      2: "text-yellow-200",
      3: "text-orange-500",
      4: "text-red-700",
    };

    const colorMapping = {};
    for (const [key, value] of Object.entries(reportColor)) {
      if (value <= max / 4) {
        colorMapping[key] = colorPointMapping[1];
      } else if (value <= (max / 4) * 2) {
        colorMapping[key] = colorPointMapping[2];
      } else if (value <= (max / 4) * 3) {
        colorMapping[key] = colorPointMapping[3];
      } else {
        colorMapping[key] = colorPointMapping[4];
      }
    }

    return colorMapping;
  };

  const generateEstimate = async (estimateUrlKey) => {
    if(!user.superAdmin){
      return;
    }

    const requestUrl = `${baseUrl}/api/claim/start-estimate`;
    const requestBody = {
      estimateUrlKey: estimateUrlKey,
      strapiToken: token
    };
    const response = await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });
  }

  const applyAllowance = async (estimateUrlKey) => {
    if(!user.superAdmin){
      return;
    }
  
    const requestUrl = `${baseUrl}/api/allowance/apply-rules`;
    const requestBody = {
      claimId: estimateUrlKey,
      strapiToken: token
    };

    const response = await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    }).then((response) => response.json())
    .then((data) => {
      if (data.status && data.status == 'error') {
        if (data.code === 'estimate_processing') {
          alert('Processing EST for this claim. Please try again later');
        }
        if (data.code === 'allowance_processing') {
          alert('Processing REV for this claim. Please try again later');
        }
        if (data.code === 'adas_processing') {
          alert('Processing ADAS for this claim. Please try again later');
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  
  const triggerAdas = async (estimateUrlKey) => {
    if(!user.superAdmin){
      return;
    }
    
    const requestUrl = `${baseUrl}/api/allowance/trigger-manual-adas`;
    const requestBody = {
      claimId: estimateUrlKey,
      strapiToken: token
    };

    const response = await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    }).then((response) => response.json())
      .then((data) => {
        if (data.status && data.status == 'error') {
          alert(data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const openChatLink = (urlKey) => {
    const chatUrl = `https://chat.trueclaim.ai/?claimId=${urlKey}`;
    window.open(chatUrl, '_blank');
  }

  const setSelectedTab = (index) => {
    tabGroupRef.current?._handlers?.setSelectedTabIndex?.(index);
    // Scroll to .tab-panels-container
    const tabPanelContainer = document.querySelector('.tab-panels-container');
    tabPanelContainer.scrollIntoView({ behavior: "smooth" });
  }

  function updatePoiSelectedImage(index) {
    setSelectedImageOpline(imagesOpline[index]);
  }

  function handlePartThumbMaskingMask(imageName) {
    let currentImageData = null;
    let parent = null;
    imagesOpline.map((image, index) => {
      if(image['name'] === imageName){
        currentImageData = image;
        parent = document.getElementById(`step4_main_container_thumb_${index}`);
      }
    });

    if(currentImageData && parent){
      const image = parent.querySelector('.step4_main_img_thumb[data-key="'+imageName+'"]');
      const imgHeight = image.height;
      const imgWidth = image.width;
      const orgImgWidth = image.naturalWidth;
      const orgImgHeight = image.naturalHeight;
      let canGenerate = false
      if(imgHeight > 0 && imgWidth > 0 && orgImgWidth > 0 && orgImgHeight > 0){
        canGenerate = true;
      }
      if(!canGenerate){
        return;
      }

      parent.querySelectorAll('.part-mask-canvas').forEach((canvas) => canvas.remove());
      let maskingData = currentImageData['masks']; 
      // Skip if PartMaskingResult is empty object or PartMaskingResult.result is empty array
      // Loop through the maskingData
      let ddMarkingData = [];
      Object.keys(maskingData).map((key, index) => {
        if(key !== 'parts'){
          if(maskingData[key].length > 0 && checkedDamages[key]) {
            // Merge ddMarkingData with maskingData[key]
            ddMarkingData = ddMarkingData.concat(maskingData[key]);
          }
        }
      });

      // Loop through the segments, create a canvas for each segment and draw the segment on the canvas
      let segmentsData = ddMarkingData;
      segmentsData.forEach((segment, index) => {
        let { name, confidence, box, segments } = segment;
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        
        canvas.height = imgHeight;
        canvas.width = imgWidth;
  
        ctx.fillStyle = `rgba(255,0,0,0.4)`;
        ctx.strokeStyle = `rgba(255,0,0,1)`;
        drawThumbSegments(canvas, ctx, [segment], orgImgWidth, orgImgHeight, name);
        // Set canvas id to the segment name
        canvas.id = `${name}_${index}`;
        canvas.className = "part-mask-canvas";
        parent.appendChild(canvas);
      });
    }
  }
  
  function drawThumbSegments(canvas, ctx, segmentsData, orgImgWidth, orgImgHeight, name) {
    const screenScaleX = canvas.width / orgImgWidth;
    const screenScaleY = canvas.height / orgImgHeight;

    segmentsData.forEach((segment) => {
      const { name, confidence, box, segments } = segment;

      const scaleX = screenScaleX;
      const scaleY = screenScaleY;
      // Draw segments
      ctx.beginPath();
      segments.x.forEach((x, i) => {
        const y = segments.y[i];
        if (i === 0) {
          ctx.moveTo(x * scaleX, y * scaleY);
        } else {
          ctx.lineTo(x * scaleX, y * scaleY);
        }
      });
      ctx.closePath();
      ctx.fill();
    });
  }
  
  function removePartMaskingMask() {
    // Remove all existing part masking canvases
    const existingCanvases = document.querySelectorAll(".main-rp-img-wrapper .part-mask-canvas");
    existingCanvases.forEach((canvas) => canvas.remove());
    return;
  }

  function handlePartMaskingMask() {
    removePartMaskingMask();

    if(selectedImageOpline){
      const maskingData = selectedImageOpline['masks']; 
      // Skip if PartMaskingResult is empty object or PartMaskingResult.result is empty array
      // Loop through the maskingData
      let ddMarkingData = [];
      Object.keys(maskingData).map((key, index) => {
        if(maskingData[key].length > 0 && checkedDamages[key]) {
          // Merge ddMarkingData with maskingData[key]
          ddMarkingData = ddMarkingData.concat(maskingData[key]);
        }
      });
  
      // Loop through the segments, create a canvas for each segment and draw the segment on the canvas
      const segmentsData = ddMarkingData;
      segmentsData.forEach((segment, index) => {
        const { name, confidence, box, segments } = segment;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        let orgImg = document.getElementById("step4_main_img");
        canvas.height = orgImg.height;
        let orgImgWidth = orgImg.naturalWidth;
        let orgImgHeight = orgImg.naturalHeight;
        let ratio = orgImgWidth / orgImgHeight;
        canvas.width = canvas.height * ratio;
  
        // Canvas color
        if(name !== 'Scratch' && name !== 'Dent' && name !== 'Tear') {
          ctx.fillStyle = `rgba(${COLORS[index % COLORS.length].r}, ${COLORS[index % COLORS.length].g}, ${COLORS[index % COLORS.length].b}, 0.7)`;
          ctx.strokeStyle = `rgba(${COLORS[index % COLORS.length].r}, ${COLORS[index % COLORS.length].g}, ${COLORS[index % COLORS.length].b}, 1)`;
        }else {
          ctx.fillStyle = `rgba(255,0,0,0.4)`;
          ctx.strokeStyle = `rgba(255,0,0,1)`;
        }
        drawSegments(canvas, ctx, [segment], orgImgWidth, orgImgHeight, name);
        // Set canvas id to the segment name
        canvas.id = `${name}_${index}`;
        // Set canvas name to the segment name. Replace underscore and dash with space. Capitalize the first letter of each word.
        const nameFormatted = name.replace(/_|-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
        canvas.setAttribute("data-name", nameFormatted);
        canvas.className = "part-mask-canvas";
        // Append the canvas to the main image wrapper
        document.querySelector(".main-rp-img-wrapper .main-rp-image-sqrimg").appendChild(canvas);
      });
    }
  }

  function drawSegments(canvas, ctx, segmentsData, orgImgWidth, orgImgHeight, name) {
    const screenScaleX = canvas.width / orgImgWidth;
    const screenScaleY = canvas.height / orgImgHeight;

    segmentsData.forEach((segment) => {
      const { name, confidence, box, segments } = segment;

      
      let confidenceScore = Math.round(confidence * 100) / 100;
      if(name !== 'Scratch' && name !== 'Dent' && name !== 'Tear') {
        var stringDamage = name;
      }else {
        var stringDamage = name + ' ' + confidenceScore;
      }

      const scaleX = screenScaleX;
      const scaleY = screenScaleY;
      ctx.strokeRect(
        box.x1 * scaleX,
        box.y1 * scaleY,
        (box.x2 - box.x1) * scaleX,
        (box.y2 - box.y1) * scaleY
      );

      // Draw text
      drawTextBG(ctx, stringDamage, "16px Arial", box.x1 * scaleX, box.y1 * scaleY - 15);

      // Draw segments
      ctx.beginPath();
      segments.x.forEach((x, i) => {
        const y = segments.y[i];
        if (i === 0) {
          ctx.moveTo(x * scaleX, y * scaleY);
        } else {
          ctx.lineTo(x * scaleX, y * scaleY);
        }
      });
      ctx.closePath();
      ctx.fill();
    });
  }

  async function sendEmailReport(emails, pdfLink) {
    try {
      let adasPdf = pdfLink;
      if(s3Data && s3Data.adasReport && Object.keys(s3Data.adasReport).length === 0 && s3Data.adasPdf){
        adasPdf = s3Data.adasPdf;
      }

      if(adasPdf){
        const emailData = {
          vehicleName: title !== 'No vehicle selected' ? title : '',
          vehicleCompany: titleHeading ? titleHeading : '',
          reportLink: adasPdf,
          emails: emails
        }
        const url = `${baseUrl}/api/tc-app/send-email-report`;
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(emailData),
        });
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  async function submitFlagMessage(event) {
    event.preventDefault();
    try {
      const url = `${baseUrl}/api/tc-app/submit-flag-message`;
      const flagMessageForm = document.getElementById('flagMessage').value;
      console.log('flagPos', flagPos);
      console.log('flagMessageForm', flagMessageForm);
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          tcUrlKey: claimData.tcUrlKey,
          flagPos: flagPos,
          message: flagMessageForm,
        }),
      });
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  }
    

  function drawTextBG(ctx, txt, font, x, y) {   
    ctx.save();
    ctx.font = font;
    ctx.textBaseline = 'top';
    ctx.fillStyle = '#ff0000';
    var width = ctx.measureText(txt).width + 5;
    ctx.fillRect(x, y, width, parseInt(font, 10));
    ctx.fillStyle = '#fff';
    ctx.fillText(txt, x, y);
    ctx.restore();
  }

  useEffect(() => {
    const initFetchData = async () => {
      await fetchClaimData();
    };

    initFetchData();
  }, []);

  if (isLoading) return <LoadingSpinner1 />;

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{( title && title !== '0 null null' ? title : 'No Vehicle Selected')}</title>
      </Helmet>
      <div className="tc-claim-title-container">
        <BaseHeaderLayout
          primaryAction={
            <div className="tc-actions-details-container">
              <div className="tc-claim-trail">
                <button data-dropdown-toggle="dropdown" 
                  onClick={() => setShowTrail(!showTrail)}
                  className="tc-trail-action text-slate-900 bg-white text-nowrap hover:bg-indigo-500 hover:text-white font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center mr-2" type="button">Claim Trail 
                  <svg className="pointer-events-none w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                  </svg>
                </button>
              </div>
              <Trail
                  showTrail={showTrail}
                  claimTrail={claimTrail}
                  formatDateData={formatDateData}
                  dropdownRef={dropdownRef}
                />
              <div className="trueclaim-col-actions">
                <span onClick={() => openChatLink(claimData.mitchellUrlKey)} className={`tc-actions-item chat ${messClass} ${onlyAdas ? 'disabled' : ''}`}>CHAT {messStep > 0 && (`(${messStep}/8)`)}</span>
                {uP !== 'PostEstimate' ? (
                  <span onClick={() => generateEstimate(claimData.mitchellUrlKey)} className={`tc-actions-item generate-estimate  ${onlyAdas ? 'disabled' : ''} ${claimData.estStatus}`}>EST</span>
                ) : (
                  <span className={`tc-actions-item generate-estimate disabled`}>EST</span>
                )}
                {uP !== 'PreEst' ? (
                  <span onClick={() => applyAllowance(claimData.mitchellUrlKey)} className={`tc-actions-item apply-allowance  ${onlyAdas ? 'disabled' : ''} ${claimData.revStatus}`}>REV</span>
                ) : (
                  <span className={`tc-actions-item apply-allowance disabled`}>REV</span>
                )}
                {uP !== 'PreEst' ? (
                  <span onClick={() => triggerAdas(claimData.mitchellUrlKey)} className={`tc-actions-item trigger-adas  ${adasStatus}`}>ADAS</span>
                ) : (
                  <span className={`tc-actions-item trigger-adas disabled`}>ADAS</span>
                )}
                
              </div>
            </div>
          }
          secondaryAction={getSecondaryAction(claimData)}
          navigationAction={<Link startIcon={<ArrowLeft />} to="/claims">Go back</Link>}
          title={(claimData.vehicleName && claimData.vehicleName !== '0 null null' ? claimData.vehicleName : 'No Vehicle Selected') + " \n" + titleHeading}
          subtitle={vehicleMileage + vehicleVin + claimUser}
          as="h2"
        />
      </div>
      <div className="tc-claim-content-container">
      <ContentLayout>
        <div>
          {isCreating && (
            <Box marginBottom={6}>
              <Status className="mt-6" showBullet={true}>
                <Loader small className="mr-2"></Loader> Creating in Mitchell...
              </Status>
            </Box>
          )}
          <TabGroup
            label="Some stuff for the label"
            id="tabs"
            initialSelectedTabIndex={initialSelectedTabIndex}
            ref={tabGroupRef}
          >
            <Tabs>
              <Tab value="info">Pre-Estimate</Tab>
              <Tab value="estimate">Estimate</Tab>
              <Tab value="adas">Post-Estimate</Tab>
              <Tab value="chat">CHAT</Tab>
              {user.superAdmin && (
                <Tab value="logs">LOGS</Tab>
              )}
            </Tabs>
            <TabPanels className="tab-panels-container">

              {uP !== 'PostEstimate' ? (
                <TabPanel>
                  <div className="mobile-tabs">
                    <div className="mobile-tab active">Pre-Estimate</div>
                  </div>
                  {onlyAdas ? (
                    <Box color="neutral800" padding={6} background="neutral0">
                      Your claim do not have damage information.
                    </Box>
                  ) : (
                    <Box color="neutral800" padding={6} background="neutral0">
                      <Grid className="tc-damage-information" gap={5}>
                        <GridItem className="tc-dmif-svg" col={4}>
                          <Box
                            className="h-full"
                            padding={4}
                            background="neutral100"
                            shadow="filterShadow"
                            borderRadius>
                            <div className="svg-box-container">
                              <PoiSvg 
                                availablePoi={availablePoi}
                                selectedPoi={selectedPoi}
                                poiDefaultSelected={poiDefaultSelected}
                                handleClickPoi={handleClickPoi}
                                defaultDamagePoi={defaultDamagePoi}
                                />
                            </div>
                          </Box>
                        </GridItem>
                        <GridItem className="tc-dmif-description" col={8}>
                          <Box
                              className="h-full"
                              padding={4}
                              background="neutral100"
                              shadow="filterShadow"
                              borderRadius
                            >
                              <Box padding={2}>
                                <Typography variant="beta">
                                  Damage Description
                                </Typography>
                              </Box>
                              <Box padding={2}>
                                  {s3Data.data.claimInfo &&
                                  s3Data.data.claimInfo.overview.vehicle.status.damageDescription && (
                                    <div className="mb-2 mt-5">
                                      <Typography variant="delta">
                                        Assignment
                                      </Typography>
                                    </div>
                                  )}
                                <Typography variant="omega" textColor="neutral500">
                                  {s3Data.data.claimInfo &&
                                  s3Data.data.claimInfo.overview.vehicle.status
                                    .damageDescription
                                    ? formatHtml(
                                      s3Data.data.claimInfo.overview.vehicle
                                      .status.damageDescription)
                                    : " "}
                                </Typography>
                              </Box>
                              {hasChatData && (
                                <Box padding={2}>
                                  <div className="mb-2 mt-5">
                                    <Typography variant="delta">
                                      Car Owner Chatbot
                                    </Typography>
                                  </div>
                                  <Typography variant="omega" textColor="neutral800">
                                    {describeDamage && (
                                      <div className="mb-2">
                                      <Typography variant="omega" textColor="neutral500">
                                      <strong className="font-bold text-white">Describe Damage:</strong> {describeDamage}
                                      </Typography>
                                      </div>
                                    )}
                                    {airbagsDeployed && (
                                      <div className="mb-2">
                                      <Typography variant="omega" textColor="neutral500">
                                      <strong className="font-bold text-white">Airbags deployed:</strong> {airbagsDeployed}
                                      </Typography>
                                      </div>
                                    )}
                                    {steeringWheelDamaged && (
                                      <div className="mb-2">
                                      <Typography variant="omega" textColor="neutral500">
                                      <strong className="font-bold text-white">Steering wheel difficult to turn when driving:</strong> {steeringWheelDamaged}
                                      </Typography>
                                      </div>
                                    )}
                                    {carDrivable && (
                                      <div className="mb-2">
                                      <Typography variant="omega" textColor="neutral500">
                                      <strong className="font-bold text-white">Drivable:</strong> {carDrivable}
                                      </Typography>
                                      </div>
                                    )}
                                    {impactOccur && (
                                      <div className="mb-2">
                                      <Typography variant="omega" textColor="neutral500">
                                      <strong className="font-bold text-white">Impact occur:</strong> {impactOccur}
                                      </Typography>
                                      </div>
                                    )}
                                    {partsAreDamaged && (
                                      <div className="mb-2">
                                      <Typography variant="omega" textColor="neutral500">
                                      <strong className="font-bold text-white">Damaged parts:</strong> {partsAreDamaged}
                                      </Typography>
                                      </div>
                                    )}

                                  </Typography>
                                </Box>
                              )}
                              {s3Data.englishDamageDescription && s3Data.englishDamageDescription !== "" && (
                                <>
                                  <Box padding={2}>
                                    <div className="mb-2 mt-5">
                                      <Typography variant="delta">
                                        ai Interpretation
                                      </Typography>
                                    </div>
                                    <Typography variant="omega" textColor="neutral500">
                                      {formatHtml(s3Data.englishDamageDescription)}
                                    </Typography>
                                  </Box>
                                </>
                              )}
                              {s3Data.aiJsonData && (s3Data.aiJsonData.assignmentMemo || s3Data.aiJsonData.partType || s3Data.aiJsonData.profile) && (
                                <>
                                    {s3Data.aiJsonData.assignmentMemo && (
                                      <Box padding={2}>
                                        <div className="mb-2 mt-5">
                                          <Typography variant="delta">
                                            Assignment Memo
                                          </Typography>
                                        </div>
                                        <div className="mb-2 description-text">
                                          <Typography variant="omega" textColor="neutral500">
                                            {formatHtml(s3Data.aiJsonData.assignmentMemo[0])}
                                          </Typography>
                                        </div>
                                      </Box>
                                    )}
                                    {s3Data.aiJsonData.partType && (
                                      <Box padding={2}>
                                        <div className="mb-2 mt-5">
                                          <Typography variant="delta">
                                            Part Type
                                          </Typography>
                                        </div>
                                        <div className="mb-2">
                                          <Typography variant="omega" textColor="neutral500">
                                            {s3Data.aiJsonData.partType}
                                          </Typography>
                                        </div>
                                      </Box>
                                    )}
                                    {s3Data.aiJsonData.profile && (
                                      <Box padding={2}>
                                        <div className="mb-2 mt-5">
                                          <Typography variant="delta">
                                            Profile
                                          </Typography>
                                        </div>
                                        <div className="mb-2">
                                          <Typography variant="omega" textColor="neutral500">
                                            {s3Data.aiJsonData.profile}
                                          </Typography>
                                        </div>
                                      </Box>
                                    )}
                                </>
                              )}
                          </Box>
                        </GridItem>
                        <ImageGallery
                          checkedDamages={checkedDamages}
                          s3Data={s3Data}
                          updateCheckedDamages={updateCheckedDamages}
                          updateLogData={updateLogData}
                          selectedPoi={selectedPoi}
                          setSelectedPoi={setSelectedPoi}
                          reportData={reportData}
                          reportPartsAvailable={reportPartsAvailable}
                          poiParts={poiParts}
                          colorMapping={colorMapping}
                          labelWithKey={labelWithKey}
                          reportDataOpLines={reportDataOpLines}
                          opLinesDiff={opLinesDiff}
                          COLORS={COLORS}
                          setCheckedDamages={setCheckedDamages}
                          defaultDamagePoi={defaultDamagePoi}
                        />
                        <GridItem col={12}>
                          <Box
                            padding={2}
                            background="neutral100"
                            shadow="filterShadow"
                            borderRadius
                          >
                            {logData ? (
                              <div className="font-mono text-sm table-report-log">
                                <Table colCount="2" rowCount={logData.length + 1}>
                                  <Tbody>
                                    <Tr>
                                      <Th>
                                        <Typography variant="sigma">
                                          Image
                                        </Typography>
                                      </Th>
                                      <Th>
                                        <Typography variant="sigma">
                                          Analysis
                                        </Typography>
                                      </Th>
                                    </Tr>
                                    {logData.map((log, index) => (
                                      <Tr key={"log" + index}>
                                        <Td className="min-w-80">
                                          <img
                                            src={getLogImageSrc(
                                              log.image,
                                              s3Data.resultImages
                                            )}
                                            alt=""
                                            className="w-64 my-8"
                                            onClick={() =>
                                              handleImageClick(
                                                getLogImageSrc(
                                                  log.image,
                                                  s3Data.resultImages
                                                )
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </Td>
                                        <Td>
                                          <Typography className="whitespace-pre-wrap">
                                            {log.analysis}
                                          </Typography>
                                        </Td>
                                      </Tr>
                                    ))}
                                  </Tbody>
                                </Table>
                              </div>
                            ) : (
                              <div>No log data found.</div>
                            )}
                          </Box>
                        </GridItem>  
                      </Grid>
                    </Box>
                  )}
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(1)}>Estimate</div>
                    <div className="mobile-tab" onClick={() => setSelectedTab(2)}>Post-Estimate</div>
                    <div className="mobile-tab" onClick={() => setSelectedTab(3)}>CHAT</div>
                  </div>
                </TabPanel>
              ) : (
                <TabPanel>
                  <div className="mobile-tabs">
                    <div className="mobile-tab active">Pre-Estimate</div>
                  </div>
                  <Box color="neutral800" padding={4} background="neutral0">
                    <p>Pre-Estimate is not available for your submission.</p>
                  </Box>
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(1)}>Estimate</div>
                    <div className="mobile-tab" onClick={() => setSelectedTab(2)}>Post-Estimate</div>
                    <div className="mobile-tab" onClick={() => setSelectedTab(3)}>CHAT</div>
                  </div>
                </TabPanel>
              )}

              {uP !== 'PostEstimate' ? (
                <TabPanel>
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(0)}>Pre-Estimate</div>
                    <div className="mobile-tab active">Estimate</div>
                  </div>
                  {onlyAdas ? (
                    <Box color="neutral800" padding={6} background="neutral0">
                      {estimatePdfLink && (
                        <iframe src={estimatePdfLink} width="100%" height="800" className="mb-6"></iframe>
                      )}
                      {claimData.extractedPdfData && (
                        <Table colCount={4} rowCount={claimData.extractedPdfData.lines.length}>
                          <Thead>
                            <Tr>
                              <Th><Typography variant="sigma">No</Typography></Th>
                              <Th><Typography variant="sigma">DB Ref</Typography></Th>
                              <Th><Typography variant="sigma">Header</Typography></Th>
                              <Th><Typography variant="sigma">Description</Typography></Th>
                              <Th><Typography variant="sigma">Operation</Typography></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {claimData.extractedPdfData.lines.map((line, index) => (
                              <Tr key={index}>
                                <Td><Typography variant="omega">{line.no}</Typography></Td>
                                <Td><Typography variant="omega">{line.dbRef}</Typography></Td>
                                <Td><Typography variant="omega">{line.header}</Typography></Td>
                                <Td><Typography variant="omega">{line.description}</Typography></Td>
                                <Td><Typography variant="omega">{line.operation}</Typography></Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                    </Box>
                  ) : (
                    <>
                      <Box color="neutral800" padding={4} background="neutral0">
                        {isReportLoading ? (
                          <LoadingSpinner1 />
                        ) : (
                          <Field>
                            <Flex direction="column" alignItems="stretch" gap={1}>
                              <Box
                                padding={1}
                                background="neutral100"
                                shadow="filterShadow"
                                className="tc-report-container"
                                borderRadius
                              >
                                {reportData.status === "success" && (
                                <div className="digram-box">
                                  <div className="main-rp-img-wrapper">
                                    {selectedImageOpline && (
                                      <>
                                        <div className="main-rp-image-sqrimg">
                                          <img
                                            src={selectedImageOpline.url}
                                            id="step4_main_img"
                                            alt={selectedImageOpline.part}
                                            onLoad={handlePartMaskingMask}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                )}
                                {reportData.status === "success" && (
                                  //Loop through the object reportData.reportJson and display the data with the color mapping in a table
                                  <div className="digram-table">
                                  <Table
                                    colCount="2"
                                    rowCount={
                                      Object.keys(reportData.reportJson).length + 1
                                    }
                                    background="neutral200"
                                  >
                                    <Thead>
                                      <Tr>
                                        <Th>
                                          <Typography variant="sigma">
                                            Part
                                          </Typography>
                                        </Th>
                                        <Th>
                                          <Typography variant="sigma">
                                            Analysis
                                          </Typography>
                                        </Th>
                                        <Th>
                                          <Typography variant="sigma">
                                            Operation
                                          </Typography>
                                        </Th>
                                        <Th>
                                          <Typography variant="sigma">
                                            Unit
                                          </Typography>
                                        </Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {Object.entries(reportData.reportJson).map(
                                        ([key, value]) => (
                                          reportPartsAvailable.includes(key) && (
                                            <Tr id={`report-part-item-${key}`} className={`report-part-item`} key={key}>
                                              <Td>
                                                <span className={colorMapping[key]}>
                                                  {labelWithKey[key] ? labelWithKey[key] : key}
                                                </span>
                                              </Td>
                                              <Td style={{maxWidth: "300px"}}>
                                                <Typography className="whitespace-pre-wrap">
                                                  {value}
                                                </Typography>
                                              </Td>
                                              <Td>
                                                <Typography className="whitespace-pre-wrap">
                                                  {reportDataOpLines[key] && reportDataOpLines[key].operation ? reportDataOpLines[key].operation : ""}
                                                </Typography>
                                              </Td>
                                              <Td>
                                                <Typography className="whitespace-pre-wrap">
                                                  {reportDataOpLines[key] && reportDataOpLines[key].units ? reportDataOpLines[key].units.toFixed(2) : ""}
                                                </Typography>
                                              </Td>
                                            </Tr>
                                          )
                                        )
                                      )}
                                      {opLinesDiff && (
                                        opLinesDiff.map((opLine, index) => (
                                          <Tr key={index}>
                                            <Td>
                                              <span>
                                                {labelWithKey[opLine.realPart] ? labelWithKey[opLine.realPart] : opLine.realPart}
                                              </span>
                                            </Td>
                                            <Td>
                                              <Typography className="whitespace-pre-wrap">
                                                {opLine.part}
                                              </Typography>
                                            </Td>
                                            <Td>
                                              <Typography className="whitespace-pre-wrap">
                                                {opLine.operation}
                                              </Typography>
                                            </Td>
                                            <Td>
                                              <Typography className="whitespace-pre-wrap">
                                                {opLine.units ? opLine.units.toFixed(2) : ""}
                                              </Typography>
                                            </Td>
                                          </Tr>
                                        ))
                                      )}
                                      <Tr>
                                        <Td colSpan={4} className="no-padding">
                                          <ImagesOpline 
                                            imagesOpline={imagesOpline}
                                            handlePartThumbMaskingMask={handlePartThumbMaskingMask}
                                            updatePoiSelectedImage={updatePoiSelectedImage}
                                            />
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                    <Tbody>
                                      <Tr>
                                        <Td colSpan={4} className="no-padding">
                                          <Accordion expanded={expanded} onToggle={() => setExpanded(s => !s)} id="acc-1" size="S">
                                            <AccordionToggle title="Previous Damage" />
                                            <AccordionContent>
                                              <Table>
                                                <Tbody>
                                                  {Object.entries(reportData.reportJson).map(
                                                    ([key, value]) => (
                                                      !reportPartsAvailable.includes(key) && (
                                                        <Tr key={key}>
                                                        <Td>
                                                          <span className={colorMapping[key]}>
                                                            {labelWithKey[key] ? labelWithKey[key] : key}
                                                          </span>
                                                        </Td>
                                                        <Td style={{maxWidth: "300px"}}>
                                                          <Typography className="whitespace-pre-wrap">
                                                            {value}
                                                          </Typography>
                                                        </Td>
                                                        <Td>
                                                          <Typography className="whitespace-pre-wrap">
                                                            {reportDataOpLines[key] && reportDataOpLines[key].operation ? reportDataOpLines[key].operation : ""}
                                                          </Typography>
                                                        </Td>
                                                        <Td>
                                                          <Typography className="whitespace-pre-wrap">
                                                            {reportDataOpLines[key] && reportDataOpLines[key].units ? reportDataOpLines[key].units.toFixed(2) : ""}
                                                          </Typography>
                                                        </Td>
                                                      </Tr>
                                                      )
                                                    )
                                                  )}
                                                </Tbody>
                                              </Table>
                                            </AccordionContent>
                                          </Accordion>
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                  </Table>
                                  </div>
                                )}
                              </Box>
                            </Flex>
                          </Field>
                        )}
                      </Box>
                      <Box color="neutral800" padding={4} background="neutral0">
                        <Grid gap={4}>
                          <GridItem col={6} s={12}>
                            <div className="mb-3">
                              <Typography variant="beta">
                                Trueclaim Estimate
                              </Typography>
                            </div>
                            <Accordion className="mb-4 border-gray" expanded={expandedTcLineDamage} onToggle={() => setExpandedTcLineDamage(s => !s)}  id="acc-2" size="S">
                              {dataTcBms === null && (
                                <AccordionToggle title={`Line Damages - No Trueclaim estimate found`} />
                              )}
                              {dataTcBms !== null && (
                                <AccordionToggle title={`Line Damages`} />
                              )}
                              <AccordionContent>
                                {dataTcBms !== null && (
                                  <>
                                    <Table
                                      className="tc-estimate-table"
                                      colCount="2"
                                      rowCount={dataTcBms.length + 1}
                                      background="neutral200"
                                    >
                                      <Thead>
                                        <Tr>
                                          <Th style={{maxWidth: "150px"}}><Typography variant="omega">LineHeaderDesc</Typography></Th>
                                          <Th style={{maxWidth: "150px"}}><Typography variant="omega">LineDesc</Typography></Th>
                                          <Th><Typography variant="omega">PartNum</Typography></Th>
                                          <Th><Typography variant="omega">PartPrice</Typography></Th>
                                          <Th><Typography variant="omega">PartType</Typography></Th>
                                          <Th><Typography variant="omega">LaborOperation</Typography></Th>
                                          <Th><Typography variant="omega">LaborHours</Typography></Th>
                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        {dataTcBms.map((item, index) => (
                                          <Tr key={index}>
                                            <Td>{ item.LineHeaderDesc }</Td>
                                            <Td>{ item.LineDesc }</Td>
                                            <Td>{ item.PartNum }</Td>
                                            <Td>{ item.PartPrice }</Td>
                                            <Td>{ item.PartType }</Td>
                                            <Td>{ bmsProfileType[item.LaborOperation] ? bmsProfileType[item.LaborOperation] : item.LaborOperation }</Td>
                                            <Td>{ item.LaborHours }</Td>
                                          </Tr>
                                        ))}
                                      </Tbody>
                                    </Table>
                                  </>
                                )}
                                {dataTcBms === null && (
                                  <Box
                                    hasRadius
                                    padding={4}
                                    background="neutral0"
                                    shadow="tableShadow"
                                  >
                                    <Typography textColor="neutral800">No data</Typography>
                                  </Box>
                                )}
                              </AccordionContent>
                            </Accordion>
                            <Accordion className="mb-4 border-gray" expanded={expandedTcPdf} onToggle={() => setExpandedTcPdf(s => !s)}  id="acc-3" size="S">
                              {!s3Data.tcEstimateHtml && (
                                <AccordionToggle title={`PDF Report - No Trueclaim estimate found`} />
                              )}
                              {s3Data.tcEstimateHtml && (
                                <AccordionToggle title={`PDF Report`} />
                              )}
                              <AccordionContent>
                                {s3Data.tcEstimateHtml && (
                                  <Box
                                    hasRadius
                                    padding={4}
                                    background="neutral1000"
                                    shadow="tableShadow"
                                    className="tc-estimate-col"
                                  >
                                    {parse(s3Data.tcEstimateHtml, {
                                      replace(domNode) {
                                        if (domNode.name === "head") {
                                          return (
                                            <div>
                                              {domToReact(
                                                domNode.children,
                                                domNode.options
                                              )}
                                            </div>
                                          );
                                        }
                                        if (domNode.name === "body") {
                                          const childrenToRender =
                                            domNode.children.filter((child) => {
                                              if (child.attribs) {
                                                if (
                                                  child.attribs.class ===
                                                    "repairLines repairLines_table estimateHtmlBody" ||
                                                  child.attribs.class ===
                                                    "sub-headings repairLines_legend estimateHtmlBody" ||
                                                  child.attribs.id === "Remarks" ||
                                                  child.attribs.class === "width-100"
                                                ) {
                                                  return true;
                                                }
                                              }

                                              return false;
                                            });

                                          return (
                                            <div className="text-black bg-white">
                                              {domToReact(
                                                childrenToRender,
                                                domNode.options
                                              )}
                                            </div>
                                          );
                                        }
                                      },
                                    })}
                                    {parse(customCss)}
                                  </Box>
                                )}
                                {!s3Data.tcEstimateHtml && (
                                  <Box
                                    hasRadius
                                    padding={4}
                                    background="neutral0"
                                    shadow="tableShadow"
                                  >
                                    <Typography textColor="neutral800">
                                      No Trueclaim estimate found.
                                    </Typography>
                                  </Box>
                                )}
                              </AccordionContent>
                            </Accordion>
                          </GridItem>
                          <GridItem col={6} s={12}>
                            <div className="mb-3">
                              <Typography variant="beta">
                                Human Estimate
                              </Typography>
                            </div>
                            <Accordion className="mb-4 border-gray" expanded={expandedTcLineDamage} onToggle={() => setExpandedTcLineDamage(s => !s)}  id="acc-4" size="S">
                              {dataHumanBms === null && (
                                <AccordionToggle title={`Line Damages - No human estimate found`} />
                              )}
                              {dataHumanBms !== null && (
                                <AccordionToggle title={`Line Damages`} />
                              )}
                              <AccordionContent>
                                {dataHumanBms !== null && (
                                  <>
                                    <Table
                                      className="tc-estimate-table"
                                      colCount="2"
                                      rowCount={dataHumanBms.length + 1}
                                      background="neutral200"
                                    >
                                      <Thead>
                                        <Tr>
                                          <Th style={{maxWidth: "150px"}}><Typography variant="omega">LineHeaderDesc</Typography></Th>
                                          <Th style={{maxWidth: "150px"}}><Typography variant="omega">LineDesc</Typography></Th>
                                          <Th><Typography variant="omega">PartNum</Typography></Th>
                                          <Th><Typography variant="omega">PartPrice</Typography></Th>
                                          <Th><Typography variant="omega">PartType</Typography></Th>
                                          <Th><Typography variant="omega">LaborOperation</Typography></Th>
                                          <Th><Typography variant="omega">LaborHours</Typography></Th>
                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        {dataHumanBms.map((item, index) => (
                                          <Tr key={index}>
                                            <Td>{ item.LineHeaderDesc }</Td>
                                            <Td>{ item.LineDesc }</Td>
                                            <Td>{ item.PartNum }</Td>
                                            <Td>{ item.PartPrice }</Td>
                                            <Td>{ item.PartType }</Td>
                                            <Td>{ bmsProfileType[item.LaborOperation] ? bmsProfileType[item.LaborOperation] : item.LaborOperation }</Td>
                                            <Td>{ item.LaborHours }</Td>
                                          </Tr>
                                        ))}
                                      </Tbody>
                                    </Table>
                                  </>
                                )}
                                {dataHumanBms === null && (
                                  <Box
                                    hasRadius
                                    padding={4}
                                    background="neutral0"
                                    shadow="tableShadow"
                                  >
                                    <Typography textColor="neutral800">No human estimate found.</Typography>
                                  </Box>
                                )}
                              </AccordionContent>
                            </Accordion>
                            <Accordion className="mb-4 border-gray" expanded={expandedTcPdf} onToggle={() => setExpandedTcPdf(s => !s)}  id="acc-5" size="S">
                              {!s3Data.humanEstimatePdf && (
                                <AccordionToggle title={`PDF Report - No human estimate found`} />
                              )}
                              {s3Data.humanEstimatePdf && (
                                <AccordionToggle title={`PDF Report`} />
                              )}
                              <AccordionContent>
                                {s3Data.humanEstimatePdf && (
                                  <Box
                                    hasRadius
                                    padding={4}
                                    background="neutral1000"
                                    shadow="tableShadow"
                                  >
                                    <div>
                                      <Document
                                        file={s3Data.humanEstimatePdf}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        className="tc-human-estimate"
                                      >
                                        {Array.apply(null, Array(numPages))
                                          .map((x, i) => i + 1)
                                          .map((page) => (
                                            <Page key={page} pageNumber={page} {...additionalProps} />
                                          ))}
                                      </Document>
                                    </div>
                                  </Box>
                                )}
                                {!s3Data.humanEstimatePdf && (
                                  <Box
                                    hasRadius
                                    padding={4}
                                    background="neutral0"
                                    shadow="tableShadow"
                                  >
                                    <Typography textColor="neutral800">
                                      No human estimate found.
                                    </Typography>
                                  </Box>
                                )}
                              </AccordionContent>
                            </Accordion>
                          </GridItem>
                          {dataDiffBms !== null && (
                            <GridItem col={12} s={12}>
                              <RadioGroup
                                labelledBy="profile-selection"
                                name="profileSelection"
                              >
                                <div className="flex py-4 items-center justify-items-center">
                                  <div>Filter:</div>
                                  <div className="ml-4">
                                    <Checkbox
                                      value={diffSetting["all"]}
                                      onChange={(e) => {
                                        updateDiffSetting("all", e.target.checked);
                                      }}
                                    >
                                      All
                                    </Checkbox>
                                  </div>
                                  <div className="ml-4">
                                    <Checkbox
                                      value={diffSetting["remove-replace"]}
                                      onChange={(e) => {
                                        updateDiffSetting("remove-replace", e.target.checked);
                                      }}
                                    >
                                      Remove Replace
                                    </Checkbox>
                                  </div>
                                  <div className="ml-4">
                                    <Checkbox
                                      value={diffSetting["blend"]}
                                      onChange={(e) => {
                                        updateDiffSetting("blend", e.target.checked);
                                      }}
                                    >
                                      Blend
                                    </Checkbox>
                                  </div>
                                  <div className="ml-4">
                                    <Checkbox
                                      value={diffSetting["repair"]}
                                      onChange={(e) => {
                                        updateDiffSetting("repair", e.target.checked);
                                      }}
                                    >
                                      Repair
                                    </Checkbox>
                                  </div>
                                </div>
                              </RadioGroup>
                              {totalDiffBmsMatched !== null && totalDiffBmsMatched.allData.length > 0 && (
                                <Accordion className="mb-4 border-gray tc-bms-accordion-matched" expanded={expandedDiffMatched} onToggle={() => setExpandedDiffMatched(s => !s)}  id="acc-matched" size="S">
                                  <AccordionToggle title={ totalDiffBmsMatched.title }/>
                                  <AccordionContent className="acc-nopading">
                                    <Table
                                      className="tc-estimate-table tc-diff-bms-table"
                                      colCount="2"
                                      rowCount={dataDiffBms.length + 1}
                                      background="neutral200"
                                    >
                                      <Thead>
                                        <Tr>
                                          <Th className="thead"><Typography variant="omega">LineHeaderDesc</Typography></Th>
                                          <Th className="thead"><Typography variant="omega">LineDesc</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartNum</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartPrice</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartType</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> LaborOperation</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> LaborHours</Typography></Th>
                                          <Th><Typography variant="omega">Status</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> PartNum</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> PartPrice</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> PartType</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> LaborOperation</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> LaborHours</Typography></Th>

                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        {totalDiffBmsMatched.allData.map((item, index) => (
                                          <Tr className={ item.status } key={index}>
                                            <Td className="thead">{ item.lLineHeaderDesc ? item.lLineHeaderDesc : '-' }</Td>
                                            <Td className="thead">{ item.lLineDesc ? item.lLineDesc : '-' }</Td>
                                            <Td>{ item.lPartNum ? item.lPartNum : '-' }</Td>
                                            <Td>{ item.lPartPrice ? item.lPartPrice : '-' }</Td>
                                            <Td>{ item.lPartType ? item.lPartType : '-' }</Td>
                                            <Td>{ item.lLaborOperation ? (bmsProfileType[item.lLaborOperation] ? bmsProfileType[item.lLaborOperation] : item.lLaborOperation) : '-' }</Td>
                                            <Td>{ item.lLaborHours ? item.lLaborHours : '-' }</Td>
                                            <Td className="diff-status"><span>{ item.status }</span></Td>
                                            <Td>{ item.rPartNum ? item.rPartNum : '-' }</Td>
                                            <Td>{ item.rPartPrice ? item.rPartPrice : '-' }</Td>
                                            <Td>{ item.rPartType ? item.rPartType : '-' }</Td>
                                            <Td>{ item.rLaborOperation ? (bmsProfileType[item.rLaborOperation] ? bmsProfileType[item.rLaborOperation] : item.rLaborOperation) : '-' }</Td>
                                            <Td>{ item.rLaborHours ? item.rLaborHours : '-' }</Td>
                                          </Tr>
                                        ))}
                                      </Tbody>
                                    </Table>
                                  </AccordionContent>
                                </Accordion>
                              )}
                              {totalDiffBmsModified !== null && totalDiffBmsModified.allData.length > 0 && (
                                <Accordion className="mb-4 border-gray tc-bms-accordion-matched" expanded={expandedDiffModified} onToggle={() => setExpandedDiffModified(s => !s)}  id="acc-modified" size="S">
                                  <AccordionToggle title={ totalDiffBmsModified.title }/>
                                  <AccordionContent className="acc-nopading">
                                    <Table
                                      className="tc-estimate-table tc-diff-bms-table"
                                      colCount="2"
                                      rowCount={dataDiffBms.length + 1}
                                      background="neutral200"
                                    >
                                      <Thead>
                                        <Tr>
                                          <Th className="thead"><Typography variant="omega">LineHeaderDesc</Typography></Th>
                                          <Th className="thead"><Typography variant="omega">LineDesc</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartNum</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartPrice</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartType</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> LaborOperation</Typography></Th>
                                          <Th><Typography variant="omega"><span>Trueclaim</span><br/> LaborHours</Typography></Th>
                                          <Th><Typography variant="omega">Status</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> PartNum</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> PartPrice</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> PartType</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> LaborOperation</Typography></Th>
                                          <Th><Typography variant="omega"><span>Human</span><br/> LaborHours</Typography></Th>

                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        {totalDiffBmsModified.allData.map((item, index) => (
                                          <Tr className={ item.status } key={index}>
                                            <Td className="thead">{ item.lLineHeaderDesc ? item.lLineHeaderDesc : '-' }</Td>
                                            <Td className="thead">{ item.lLineDesc ? item.lLineDesc : '-' }</Td>
                                            <Td>{ item.lPartNum ? item.lPartNum : '-' }</Td>
                                            <Td>{ item.lPartPrice ? item.lPartPrice : '-' }</Td>
                                            <Td>{ item.lPartType ? item.lPartType : '-' }</Td>
                                            <Td>{ item.lLaborOperation ? (bmsProfileType[item.lLaborOperation] ? bmsProfileType[item.lLaborOperation] : item.lLaborOperation) : '-' }</Td>
                                            <Td>{ item.lLaborHours ? item.lLaborHours : '-' }</Td>
                                            <Td className="diff-status"><span>{ item.status }</span></Td>
                                            <Td className={`${item.rPartNum !== item.lPartNum ? "highlight" : ""}`}>
                                              { item.rPartNum ? item.rPartNum : '-' }</Td>
                                            <Td className={`${item.rPartPrice !== item.lPartPrice ? "highlight" : ""}`}>
                                              { item.rPartPrice ? item.rPartPrice : '-' }</Td>
                                            <Td className={`${item.rPartType !== item.lPartType ? "highlight" : ""}`}>
                                              { item.rPartType ? item.rPartType : '-' }</Td>
                                            <Td className={`${item.rLaborOperation !== item.lLaborOperation ? "highlight" : ""}`}>
                                            { item.rLaborOperation ? (bmsProfileType[item.rLaborOperation] ? bmsProfileType[item.rLaborOperation] : item.rLaborOperation) : '-' }</Td>
                                            <Td className={`${item.rLaborHours !== item.lLaborHours ? "highlight" : ""}`}>
                                              { item.rLaborHours ? item.rLaborHours : '-' }</Td>
                                          </Tr>
                                        ))}
                                      </Tbody>
                                    </Table>
                                  </AccordionContent>
                                </Accordion>
                              )}
                              {totalDiffBmsAdded !== null && totalDiffBmsAdded.allData.length > 0 && (
                                <Accordion className="mb-4 border-gray tc-bms-accordion-matched" expanded={expandedDiffAdded} onToggle={() => setExpandedDiffAdded(s => !s)}  id="acc-added" size="S">
                                  <AccordionToggle title={ totalDiffBmsAdded.title }/>
                                    <AccordionContent className="acc-nopading">
                                      <Table
                                        className="tc-estimate-table tc-diff-bms-table"
                                        colCount="2"
                                        rowCount={dataDiffBms.length + 1}
                                        background="neutral200"
                                      >
                                        <Thead>
                                          <Tr>
                                            <Th className="thead"><Typography variant="omega">LineHeaderDesc</Typography></Th>
                                            <Th className="thead"><Typography variant="omega">LineDesc</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartNum</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartPrice</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartType</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> LaborOperation</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> LaborHours</Typography></Th>
                                            <Th><Typography variant="omega">Status</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> PartNum</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> PartPrice</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> PartType</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> LaborOperation</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> LaborHours</Typography></Th>

                                          </Tr>
                                        </Thead>
                                        <Tbody>
                                          {totalDiffBmsAdded.allData.map((item, index) => (
                                            <Tr className={ item.status } key={index}>
                                              <Td className="thead">{ item.rLineHeaderDesc ? item.rLineHeaderDesc : '-' }</Td>
                                              <Td className="thead">{ item.rLineDesc ? item.rLineDesc : '-' }</Td>
                                              <Td>{ item.lPartNum ? item.lPartNum : '-' }</Td>
                                              <Td>{ item.lPartPrice ? item.lPartPrice : '-' }</Td>
                                              <Td>{ item.lPartType ? item.lPartType : '-' }</Td>
                                              <Td>{ item.lLaborOperation ? (bmsProfileType[item.lLaborOperation] ? bmsProfileType[item.lLaborOperation] : item.lLaborOperation) : '-' }</Td>
                                              <Td>{ item.lLaborHours ? item.lLaborHours : '-' }</Td>
                                              <Td className="diff-status"><span>{ item.status }</span></Td>
                                              <Td>{ item.rPartNum ? item.rPartNum : '-' }</Td>
                                              <Td>{ item.rPartPrice ? item.rPartPrice : '-' }</Td>
                                              <Td>{ item.rPartType ? item.rPartType : '-' }</Td>
                                              <Td>{ item.rLaborOperation ? (bmsProfileType[item.rLaborOperation] ? bmsProfileType[item.rLaborOperation] : item.rLaborOperation) : '-' }</Td>
                                              <Td>{ item.rLaborHours ? item.rLaborHours : '-' }</Td>
                                            </Tr>
                                          ))}
                                        </Tbody>
                                      </Table>
                                  </AccordionContent>
                                </Accordion>
                              )}
                              {totalDiffBmsDeleted !== null && totalDiffBmsDeleted.allData.length > 0 && (
                                <Accordion className="mb-4 border-gray tc-bms-accordion-matched" expanded={expandedDiffDeleted} onToggle={() => setExpandedDiffDeleted(s => !s)}  id="acc-added" size="S">
                                  <AccordionToggle title={ totalDiffBmsDeleted.title }/>
                                    <AccordionContent className="acc-nopading">
                                      <Table
                                        className="tc-estimate-table tc-diff-bms-table"
                                        colCount="2"
                                        rowCount={dataDiffBms.length + 1}
                                        background="neutral200"
                                      >
                                        <Thead>
                                          <Tr>
                                            <Th className="thead"><Typography variant="omega">LineHeaderDesc</Typography></Th>
                                            <Th className="thead"><Typography variant="omega">LineDesc</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartNum</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartPrice</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> PartType</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> LaborOperation</Typography></Th>
                                            <Th><Typography variant="omega"><span>Trueclaim</span><br/> LaborHours</Typography></Th>
                                            <Th><Typography variant="omega">Status</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> PartNum</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> PartPrice</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> PartType</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> LaborOperation</Typography></Th>
                                            <Th><Typography variant="omega"><span>Human</span><br/> LaborHours</Typography></Th>

                                          </Tr>
                                        </Thead>
                                        <Tbody>
                                          {totalDiffBmsDeleted.allData.map((item, index) => (
                                            <Tr className={ item.status } key={index}>
                                              <Td className="thead">{ item.lLineHeaderDesc ? item.lLineHeaderDesc : '-' }</Td>
                                              <Td className="thead">{ item.lLineDesc ? item.lLineDesc : '-' }</Td>
                                              <Td>{ item.lPartNum ? item.lPartNum : '-' }</Td>
                                              <Td>{ item.lPartPrice ? item.lPartPrice : '-' }</Td>
                                              <Td>{ item.lPartType ? item.lPartType : '-' }</Td>
                                              <Td>{ item.lLaborOperation ? (bmsProfileType[item.lLaborOperation] ? bmsProfileType[item.lLaborOperation] : item.lLaborOperation) : '-' }</Td>
                                              <Td>{ item.lLaborHours ? item.lLaborHours : '-' }</Td>
                                              <Td className="diff-status"><span>{ item.status }</span></Td>
                                              <Td>{ item.rPartNum ? item.rPartNum : '-' }</Td>
                                              <Td>{ item.rPartPrice ? item.rPartPrice : '-' }</Td>
                                              <Td>{ item.rPartType ? item.rPartType : '-' }</Td>
                                              <Td>{ item.rLaborOperation ? (bmsProfileType[item.rLaborOperation] ? bmsProfileType[item.rLaborOperation] : item.rLaborOperation) : '-' }</Td>
                                              <Td>{ item.rLaborHours ? item.rLaborHours : '-' }</Td>
                                            </Tr>
                                          ))}
                                        </Tbody>
                                      </Table>
                                    </AccordionContent>
                                  </Accordion>
                              )}
                            </GridItem>
                          )}
                        </Grid>
                      </Box>
                      <Box color="neutral800" padding={6} background="neutral0">
                        <Grid gap={5}>
                          <GridItem col={12}>
                            <Box
                              className="h-full"
                              padding={4}
                              background="neutral100"
                              shadow="filterShadow"
                              borderRadius
                            >
                              <Box padding={2}>
                                <Typography variant="sigma">
                                  Confidence & Delta score
                                </Typography>
                              </Box>
                              <Box padding={2}>
                                <Typography variant="beta" textColor="neutral800">&nbsp;</Typography>
                                { isDeltaReportLoading && (
                                  <LoadingSpinner1 />
                                )}
                                { !isDeltaReportLoading && deltaReportData.status === "success" && (
                                  <Table colCount="4" rowCount="">
                                    <Thead>
                                      <Tr>
                                        <Th>
                                          <Typography variant="sigma">&nbsp;</Typography>
                                        </Th>
                                        <Th>
                                          <Typography variant="sigma">Accuracy</Typography>
                                        </Th>
                                        <Th>
                                          <Typography variant="sigma">Precision</Typography>
                                        </Th>
                                        <Th>
                                          <Typography variant="sigma">Recall</Typography>
                                        </Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      <Tr>
                                        <Td>
                                          <Typography variant="delta">Overall</Typography>
                                        </Td>
                                        <Td>
                                          <Typography variant="delta">{deltaReportData.deltaReportJson.Overall.Accuracy}</Typography>
                                        </Td>
                                        <Td>
                                          <Typography variant="delta">{deltaReportData.deltaReportJson.Overall.Precision}</Typography>
                                        </Td>
                                        <Td>
                                          <Typography variant="delta">{deltaReportData.deltaReportJson.Overall.Recall}</Typography>
                                        </Td>
                                      </Tr>
                                      { Object.keys(deltaReportData.deltaReportJson.Sections).map((key, index) => {
                                        return (
                                          <Tr key={index}>
                                            <Td>
                                              <Typography variant="pi">{key}</Typography>
                                            </Td>
                                            <Td>
                                              <Typography variant="pi">{deltaReportData.deltaReportJson.Sections[key].Accuracy}</Typography>
                                            </Td>
                                            <Td>
                                              <Typography variant="pi">{deltaReportData.deltaReportJson.Sections[key].Precision}</Typography>
                                            </Td>
                                            <Td>
                                              <Typography variant="pi">{deltaReportData.deltaReportJson.Sections[key].Recall}</Typography>
                                            </Td>
                                          </Tr>
                                        );
                                      })}
                                    </Tbody>
                                  </Table>
                                )}
                                { !isDeltaReportLoading && deltaReportData.status === "success" && (
                                  <Accordion expanded={deltaReportDetailExpanded} onToggle={() => setDeltaReportDetailExpanded(s => !s)} id="delta-report-detail" size="S">
                                    <AccordionToggle title="Delta report detail" />
                                    <AccordionContent>
                                      <Box padding={4}>
                                        <Typography>
                                          <Markdown className="markdown-body">{deltaReportData.deltaReportDetail}</Markdown>
                                        </Typography>
                                      </Box>
                                    </AccordionContent>
                                  </Accordion>
                                )}
                                { !isDeltaReportLoading && deltaReportData.status !== "success" && (
                                  <Typography>Delta report is not available for this estimate.</Typography>
                                )}
                              </Box>
                            </Box>
                          </GridItem>
                        </Grid>
                      </Box>
                    </>
                  )}
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(2)}>Post-Estimate</div>
                    <div className="mobile-tab" onClick={() => setSelectedTab(3)}>CHAT</div>
                  </div>
                </TabPanel>
              ) : (
                <TabPanel>
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(0)}>Pre-Estimate</div>
                    <div className="mobile-tab active">Estimate</div>
                  </div>
                  <Box color="neutral800" padding={4} background="neutral0">
                    <p>Estimate is not available for your submission.</p>
                  </Box>
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(2)}>Post-Estimate</div>
                    <div className="mobile-tab" onClick={() => setSelectedTab(3)}>CHAT</div>
                  </div>
                </TabPanel>
              )}

              {uP !== 'PreEst' ? (
                <TabPanel>
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(0)}>Pre-Estimate</div>
                    <div className="mobile-tab" onClick={() => setSelectedTab(1)}>Estimate</div>
                    <div className="mobile-tab active">Post-Estimate</div>
                  </div>
                  {onlyAdas ? (
                    <Box color="neutral800" padding={4} background="neutral0">
                      {isAdasReportLoading && (<LoadingSpinner1 />)}
                      {(!isAdasReportLoading && (<Adas 
                                                    adasReportData={adasReportData} 
                                                    setAdasReportData={setAdasReportData}
                                                    positionStatement={positionStatement} 
                                                    isSuperAdmin={user.superAdmin} 
                                                    sendEmailReport={sendEmailReport}
                                                    isSendingEmail={isSendingEmail}
                                                    setIsSendingEmail={setIsSendingEmail}
                                                    customOperation={customOperation}
                                                    setCustomOperation={setCustomOperation}
                                                    claimData={claimData}
                                                    toggleDrawer={toggleDrawer}
                                                  />))}
                    </Box>
                  ) : (
                    <Box color="neutral800" padding={4} background="neutral0">
                      <Adas 
                        adasReportData={s3Data.adasReport ? s3Data.adasReport : null} 
                        positionStatement={positionStatement} 
                        isSuperAdmin={user.superAdmin}
                        sendEmailReport={sendEmailReport}
                        setIsSendingEmail={setIsSendingEmail}
                        customOperation={customOperation}
                        setCustomOperation={setCustomOperation}
                        isSendingEmail={isSendingEmail}
                        toggleDrawer={toggleDrawer}
                        />
                    </Box>
                  )}
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(3)}>CHAT</div>
                  </div>
                </TabPanel>
              ) : (
                <TabPanel>
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(0)}>Pre-Estimate</div>
                    <div className="mobile-tab" onClick={() => setSelectedTab(1)}>Estimate</div>
                    <div className="mobile-tab active">Post-Estimate</div>
                  </div>
                  <Box color="neutral800" padding={4} background="neutral0">
                    <p>Post-Estimate is not available for your submission.</p>
                  </Box>
                  <div className="mobile-tabs">
                    <div className="mobile-tab" onClick={() => setSelectedTab(3)}>CHAT</div>
                  </div>
                </TabPanel>
              )}

              
              <TabPanel>
                <div className="mobile-tabs">
                  <div className="mobile-tab" onClick={() => setSelectedTab(0)}>Pre-Estimate</div>
                  <div className="mobile-tab" onClick={() => setSelectedTab(1)}>Estimate</div>
                  <div className="mobile-tab" onClick={() => setSelectedTab(2)}>Post-Estimate</div>
                </div>
                {onlyAdas ? (
                  <Box color="neutral800" padding={6} background="neutral0">Your claim not available for chat.</Box>
                ) : (
                  <Box color="neutral800" padding={4} background="neutral0">
                    <ChatList
                      messages={messages}
                    />
                  </Box>
                )}
                <div className="mobile-tabs">
                  <div className="mobile-tab active">CHAT</div>
                </div>
              </TabPanel>

              {user.superAdmin && (
                <ClaimDetailLogs 
                  isProcessLogsLoading={isProcessLogsLoading}
                  processLogsData={processLogsData}
                  />
              )}

            </TabPanels>
          </TabGroup>
          <ImageDialog
            open={imageOpen}
            handleClose={handleImageClose}
            image={image}
          />
        </div>
      </ContentLayout>
      <Drawer
          anchor="bottom"
          open={open}
          onClose={() => setOpen(false)}
        >
          <div className="flex items-center justify-center">
              <div className="max-w-screen-lg w-full p-3">
                <div className="flex items-start justify-between">
                  <div className="">
                    <p>Thank you for flagging this feature, we will be looking in to this as soon as possible.</p>
                    <br/>
                    <br/>
                    <p className="fr">Merci d'avoir signalé cette fonctionnalité, nous y réfléchirons dès que possible.</p>
                  </div>
                  <form className="w-full" onSubmit={submitFlagMessage}>
                    <input type="hidden" name="tcUrlKey" value={claimData.tcUrlKey} />
                    <input type="hidden" name="flagPos" value={flagPos} />
                    <textarea id="flagMessage" name="flagMessage" className="w-full h-32 border border-gray-300 rounded-md p-2" placeholder="Please tell me more detail (Optional)"></textarea>
                    <button type="submit" className="w-full bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-4 px-4 rounded mt-2" >Submit</button>
                  </form>
                </div>
              </div>
          </div>
        </Drawer>
      </div>
    </Layout>
  );
};

export default ClaimDetails;