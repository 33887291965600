import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalContextState } from "../../context/globalContext";
import Toolbar from '../Toolbar/Toolbar';
import { ContentLayout, BaseHeaderLayout, Loader, Box, Grid, GridItem } from "@strapi/design-system";
import { Typography, Table, Thead, Tbody, Tr, Td, Th, Button } from '@strapi/design-system';
import { Searchbar, SearchForm } from '@strapi/design-system';
import { baseUrl } from "../../config";
import { Plus } from "@strapi/icons";
import DatePicker from 'react-datepicker';
import { Helmet } from "react-helmet";
import 'react-datepicker/dist/react-datepicker.css';
import Tooltip from '@mui/material/Tooltip';

const getClaimByUser = `${baseUrl}/api/claim/get-user-claims`;

export default function ClaimsList() {
  const token  = useContext(GlobalContextState).token;
  const user = useContext(GlobalContextState).user;
  const uP = user.subscriptionPlan;
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalClaims, setTotalClaims] = useState(0);
  const [searchId, setSearchId] = useState('nos');
  const [searchUser, setSearchUser] = useState('nos');
  const [claimsListDataRb, setClaimsListDataRb] = useState([]);
  const [sortTcType, setSortTcType] = useState('');
  const [sortHumanType, setSortHumanType] = useState('');
  const [sortUserType, setSortUserType] = useState('');
  const [estDone, setEstDone] = useState(false);
  const [revDone, setRevDone] = useState(false);
  const [adasDone, setAdasDone] = useState(false);
  const [chatDone, setChatDone] = useState(false);
  const [filterDate, setFilterDate] = useState('');
  const [copiedString, setCopiedString] = useState(null);
  const tableRef = useRef(null); // Reference for the table
  const [isSticky, setIsSticky] = useState(false);
  
  useEffect(() => {
    const initFetchData = async () => {
      await fetchClaims(estDone, revDone, adasDone, chatDone);

      if(user.superAdmin){
        const url = `${baseUrl}/api/users`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setAllUsers(data);
      }
    };

    initFetchData();
  }, [pageSize, activePage]);

  useEffect(() => {
    if(searchId.length > 3 || searchId.length === 0){
      fetchClaims(estDone, revDone, adasDone, chatDone);
    }
  }, [searchId]);

  useEffect(() => {
    if(filterDate){
      fetchClaims(estDone, revDone, adasDone, chatDone);
    } 
  }, [filterDate]);

  useEffect(() => {
    if(searchUser.length > 4 || searchUser.length === 0){
      fetchClaims(estDone, revDone, adasDone, chatDone);
    }
  }, [searchUser]);

  const updatePageSize = (pSize) => {
    setPageSize(pSize);
  }

  const updateActivePage = (pActive) => {
    setActivePage(pActive);
  }

  const resetFormSearch = () => {
    setSearchId('');
    fetchClaims(estDone, revDone, adasDone, chatDone);
  }

  const fetchEstDoneClaims = async () => {
    setRevDone(false);
    setAdasDone(false);
    const newStatus = !estDone;
    setEstDone(newStatus);
    fetchClaims(newStatus, false, false, chatDone);
  }

  const fetchRevDoneClaims = async () => {
    setEstDone(false);
    setAdasDone(false);
    const newStatus = !revDone;
    setRevDone(newStatus);
    fetchClaims(false, newStatus, false, chatDone);
  }

  const fetchAdasDoneClaims = async () => {
    setEstDone(false);
    setRevDone(false);
    const newStatus = !adasDone;
    setAdasDone(newStatus);
    fetchClaims(false, false, newStatus, chatDone);
  }

  const fetchChatDoneClaims = async () => {
    const newStatus = !chatDone;
    setChatDone(newStatus);
    fetchClaims(estDone, revDone, adasDone, newStatus);
  }

  async function fetchClaims(estDoneFt, revDoneFt, adasDoneFt, chatDoneFt) {
    try {
      setLoading(true);
      setClaimsListDataRb([]);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      let fetchUrl = `${getClaimByUser}?pageSize=${pageSize}`;
      let resetPager = false;
      if(searchId && searchId.length > 3 && searchId !== 'nos'){
        fetchUrl += `&search=${searchId}`;
        resetPager = true;
      }

      if(searchUser && searchUser.length > 4 && searchUser !== 'nos'){
        fetchUrl += `&searchUser=${searchUser}`;
        resetPager = true;
      }

      if(resetPager){
        fetchUrl += `&page=1`;
      }else {
        fetchUrl += `&page=${activePage}`;
      }

      if(filterDate){
        // Format the date to be in the format of yyyy-mm-dd
        const date = new Date(filterDate);
        const formattedDate = date.toISOString().split('T')[0];
        fetchUrl += `&filterDate=${formattedDate}`;
      }

      if(estDoneFt){ fetchUrl += `&estDone=1`; }
      if(revDoneFt){ fetchUrl += `&revDone=1`; }
      if(adasDoneFt){ fetchUrl += `&adasDone=1`; }
      if(chatDoneFt){ fetchUrl += `&chatDone=1`; }

      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if(data){
          generateEstimateData(data.data.attributes.results);
          setTotalClaims(data.data.attributes.pagination.total);
          setPageCount(data.data.attributes.pagination.pageCount);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const getChatMessagesCount = async (messsages) => {
    let doneSteps = [];
    if(messsages.length === 0){
      return doneSteps;
    }

    await messsages.forEach( (message) => {
      if (message.action === "upload-image" && message.type === "image") {
        doneSteps.push('upload-image');
      }
      if (message.action === "confirm-airbags") {
        doneSteps.push('confirm-airbags');
      }
      if (message.action === "confirm-steering") {
        doneSteps.push('confirm-steering');
      }
      if (message.action === "confirm-mileage") {
        doneSteps.push('confirm-mileage');
      }
      if (message.action === "confirm-vin") {
        doneSteps.push('confirm-vin');
      }
      if (message.action === "confirm-pois") {
        doneSteps.push('confirm-pois');
      }
      if (message.action === "confirm-parts") {
        doneSteps.push('confirm-parts');
      }
      if (message.action === "session-end") {
        doneSteps.push('session-end');
      }
    });

    // Remove duplicates
    doneSteps = doneSteps.filter((item, index) => doneSteps.indexOf(item) === index);

    return doneSteps;
  }

  const capitalizeStr = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const generateEstimateData = async (claimData) => {
    try {
      const claimsListData = [];
      const mitchellFormData = [];
      
      claimData.forEach( async (claim) => {
        let messClass = '';
        let onlyAdas = true;
        let totalSteps = 0;
        if(claim.mitchellUrlKey){
          onlyAdas = false;
          const mitchellData = {
            mitchellUrlKey: claim.mitchellUrlKey,
            mitchellId: claim.user.mitchellId
          }
          mitchellFormData.push(mitchellData);

          if(claim.ownerMsgSent){
            messClass = 'owner-msg-sent';
          }

          const checkSteps = ['upload-image', 'confirm-airbags', 'confirm-steering', 'confirm-mileage', 'confirm-vin', 'confirm-pois', 'confirm-parts', 'session-end'];
          
          const messages = claim.chat_messages;
          const doneSteps = await getChatMessagesCount(messages);
          
          // If checkSteps and doneSteps are the same, then the chat is complete
          totalSteps = doneSteps.length;
          if(doneSteps.length > 0){
            if(doneSteps.length === checkSteps.length){
              messClass = 'owner-msg-completed';
            }else {
              messClass = 'owner-msg-sent-but-not-complete';
            }
          }
        }

        const claimColumnData = {
          id: claim.id,
          onlyAdas: onlyAdas,
          urlkey: claim.tcUrlKey,
          mitchellUrlKey: claim.mitchellUrlKey,
          vehicleVin: claim.vehicleVin,
          vehicle: claim.vehicleName,
          tcEstimate: 0,
          humanEstimate: 0,
          messComplete: 0,
          messClass: messClass,
          messStep: totalSteps,
          createdAt: new Date(claim.createdAt).toLocaleString('en-US', { timeZone: 'America/New_York', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }),
          user: claim.user.company ? claim.user.company : claim.user.username,
          estStatus: claim.estStatus,
          revStatus: claim.revStatus,
          trailStatusCheck: '',
          trailStatusClass: '',
          claimEstimated: '',
          estimateApproved: false,
          adasStatus: claim.adasStatus
        }
        claimsListData.push(claimColumnData);
      });

      try {
        const url = `${baseUrl}/api/tc-app/get-bms-data`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ data: mitchellFormData }),
        });

        const data = await response.json();
        data.forEach( (claim) => {
          // If the claim is not found in the data, then skip it
          if(!claim){
            return;
          }

          claimsListData.forEach( async (claimlData) => {
            if(claimlData.mitchellUrlKey === claim.urlkey){
              claimlData.tcEstimate = claim.dataTcBms;
              claimlData.trailStatusCheck = claim.trailStatusCheck;
              claimlData.trailStatusClass = claim.trailStatusClass;
              claimlData.claimEstimated = claim.claimEstimated;
              if(claim.dataHumanBms !== 0){
                claimlData.estimateApproved = true;
              }
              if(claim.dataHumanBms === 0 && claim.claimEstimated !== ''){
                claimlData.humanEstimate = claim.claimEstimated;
              }else {
                claimlData.humanEstimate = claim.dataHumanBms;
              }
            }
          });
        });
      } catch (error) {
        console.error(error);
      }

      claimsListData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setClaimsListDataRb(claimsListData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const processStatusMap = {
    pending: "pending",
    created: "pending",
    processing: "processing",
    mitchell_estimating: "processing",
    completed: "completed",
    failed: "failed",
  };

  const sortByTcEstimate = () => {
    setSortHumanType('');
    setSortUserType('');
    if(sortTcType === 'desc'){
      const sortedData = claimsListDataRb.sort((a, b) => a.tcEstimate - b.tcEstimate);
      setClaimsListDataRb([...sortedData]);
      setSortTcType('asc');
    }else{
      const sortedData = claimsListDataRb.sort((a, b) => b.tcEstimate - a.tcEstimate);
      setClaimsListDataRb([...sortedData]);
      setSortTcType('desc');
    }
  };
  
  const sortByHumanEstimate = () => {
    setSortTcType('');
    setSortUserType('');
    if(sortHumanType === 'desc'){
      const sortedData = claimsListDataRb.sort((a, b) => a.humanEstimate - b.humanEstimate);
      setClaimsListDataRb([...sortedData]);
      setSortHumanType('asc');
    }else{
      const sortedData = claimsListDataRb.sort((a, b) => b.humanEstimate - a.humanEstimate);
      setClaimsListDataRb([...sortedData]);
      setSortHumanType('desc');
    }
  };

  const sortByUser = () => {
    setSortTcType('');
    setSortHumanType('');
    if(sortUserType === 'desc'){
      const sortedData = claimsListDataRb.sort((a, b) => a.user.localeCompare(b.user));
      setClaimsListDataRb([...sortedData]);
      setSortUserType('asc');
    }else{
      const sortedData = claimsListDataRb.sort((a, b) => b.user.localeCompare(a.user));
      setClaimsListDataRb([...sortedData]);
      setSortUserType('desc');
    }
  };

  const handleDateFilter = (date) => {
    setFilterDate(date);
  }

  const generateEstimate = async (estimateUrlKey) => {
    if(!user.superAdmin){
      return;
    }

    const requestUrl = `${baseUrl}/api/claim/start-estimate`;
    const requestBody = {
      estimateUrlKey: estimateUrlKey,
      strapiToken: token
    };
    await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });
  }

  const applyAllowance = async (estimateUrlKey) => {
    if(!user.superAdmin){
      return;
    }
  
    const requestUrl = `${baseUrl}/api/allowance/apply-rules`;
    const requestBody = {
      claimId: estimateUrlKey,
      strapiToken: token
    };

    await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    }).then((response) => response.json())
    .then((data) => {
      if (data.status && data.status === 'error') {
        if (data.code === 'estimate_processing') {
          alert('Processing EST for this claim. Please try again later');
        }
        if (data.code === 'allowance_processing') {
          alert('Processing REV for this claim. Please try again later');
        }
        if (data.code === 'adas_processing') {
          alert('Processing ADAS for this claim. Please try again later');
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  
  const triggerAdas = async (estimateUrlKey) => {
    if(!user.superAdmin){
      return;
    }
    
    const requestUrl = `${baseUrl}/api/allowance/trigger-manual-adas`;
    const requestBody = {
      claimId: estimateUrlKey,
      strapiToken: token
    };

    await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    }).then((response) => response.json())
      .then((data) => {
        if (data.status && data.status === 'error') {
          alert(data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const openChatLink = (urlKey) => {
    const chatUrl = `https://chat.trueclaim.ai/?claimId=${urlKey}`;
    window.open(chatUrl, '_blank');
  }

  const handleCreateClaim = () => {
    // Change the url to the create claim page
    window.location.href = '/claims/create';
  }

  const handleCopy = (string) => {
    navigator.clipboard.writeText(string).then(() => {
      setCopiedString(string);
      
      setTimeout(() => {
        setCopiedString(null);
      }, 2000);
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const tablePosition = tableRef.current.getBoundingClientRect().top;
        const tablePostLeft = tableRef.current.getBoundingClientRect().left;
        const tablePosRight = tableRef.current.getBoundingClientRect().right;
        
        const tableHeader = document.querySelector('.table-list-header');
        tableHeader.style.left = `${tablePostLeft}px`;
        tableHeader.style.right = `${window.innerWidth - tablePosRight}px`;
        document.querySelectorAll('.table-sticky-header tr th').forEach((th, index) => {
          const width = th.offsetWidth;  // Get the current width of the <th>
          
          // Set the width for the <th>
          th.style.width = `${width}px`;
        
          // Find all <td> in the same column (matching the same index)
          document.querySelectorAll(`.table-sticky-header tr td:nth-child(${index + 1})`).forEach(td => {
            // Set the same width for the <td>
            td.style.width = `${width}px`;
          });
        });
        // Add the sticky class if the table starts to overlap the header area
        if (tablePosition <= 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim App - Claims</title>
      </Helmet>
      <div className="base-header-layout">
        <BaseHeaderLayout
          title="Claims"
          subtitle={totalClaims + " entries found"}
          as="h2"
          primaryAction={
            <>
            <Button
              onClick={handleCreateClaim}
              startIcon={<Plus />}
              className="button-new-claim"
            >
              New Claim
            </Button>
            <Button
              onClick={() => fetchClaims(estDone, revDone, adasDone, chatDone)}
              className="btn-refresh"
            >
              Refresh
            </Button>
            </>
          }
        />
      </div>
      <div className="base-header-layout">
      <ContentLayout>
        <Box className="loading-center list-claims" color="neutral800" padding={4} background="neutral0">
          <Grid className="mb-fw-ct z-20 relative" gap={3}>
            <GridItem className="mb-fw filter-claims" col="12">
              <SearchForm>
                <Searchbar name="searchbar" onClear={() => resetFormSearch()} value={searchId !== 'nos' ? searchId : ''} onChange={e => setSearchId(e.target.value)} clearLabel="Clearing the plugin search" placeholder="Search">
                  Search
                </Searchbar>
              </SearchForm>

              <DatePicker
                showIcon
                selected={filterDate}
                onChange={date => handleDateFilter(date)}
                className="custom-datepicker"
              />
              
              <button className={`btn btn-primary small ${loading ? 'disabled' : ''} ${chatDone ? 'active' : ''}`}
                      onClick={() => fetchChatDoneClaims()}>CHAT</button>
              {uP !== 'PostEstimate' ? (
                <button className={`btn btn-primary small ${loading ? 'disabled' : ''} ${estDone ? 'active' : ''}`}
                      onClick={() => fetchEstDoneClaims()}>EST</button>
              ) : (
                <button className={`btn btn-primary small disabled`}>EST</button>
              )}

              {uP !== 'PreEst' ? (
                <button className={`btn btn-primary small ${loading ? 'disabled' : ''} ${revDone ? 'active' : ''}`}
                  onClick={() => fetchRevDoneClaims()}>REV</button>
              ) : (
                <button className={`btn btn-primary small disabled`}>REV</button>
              )}

              {uP !== 'PreEst' ? (
                <button className={`btn btn-primary small ${loading ? 'disabled' : ''} ${adasDone ? 'active' : ''}`}
                onClick={() => fetchAdasDoneClaims()}>ADAS</button>
              ) : (
                <button className={`btn btn-primary small disabled`}>ADAS</button>
              )}
            </GridItem>
            {user.superAdmin && (
              <GridItem className="mb-fw search-user-form" col="12">
                <select className="select-user" value={searchUser} onChange={e => setSearchUser(e.target.value)}>
                  <option value="nos">Select User</option>
                  {allUsers && allUsers.map((userFilter) => (
                    !userFilter.superAdmin && (
                      <option key={userFilter.id} value={userFilter.username}>{`${userFilter.username} - ${userFilter.company}`}</option>
                    )
                  ))}
                </select>
              </GridItem>
            )}
          </Grid>
          {loading ? (
            <Loader/>
          ) : (
            <div 
            ref={tableRef} 
            className={`table-responsive-block z-10 mt-4 table-sticky-header ${isSticky ? 'sticky-header' : ''}`}>
              <Table 
              colCount={6} 
              rowCount={claimsListDataRb.length}>
                <Thead className="table-list-header">
                  <Tr>
                    <Th>
                      <Typography variant="sigma">Vehicle</Typography>
                    </Th>
                    <Th className="hidden-mobile">
                      <Typography variant="sigma">VIN</Typography>
                    </Th>
                    <Th className="cursor-n-resize hidden-mb w-20" onClick={() => sortByTcEstimate()}>
                      <Typography variant="sigma">Tc Est</Typography>
                    </Th>
                    <Th className="cursor-n-resize hidden-mb w-20" onClick={() => sortByHumanEstimate()}>
                      <Typography variant="sigma" >Human Est</Typography>
                    </Th>
                    {user.superAdmin && (
                      <Th className="cursor-n-resize hidden-mb" onClick={() => sortByUser()}>
                        <Typography variant="sigma">Garage</Typography>
                      </Th>
                    )}
                    <Th className="hidden-mb w-20">
                      <Typography variant="sigma">Created At</Typography>
                    </Th>
                    <Th className="w-16">
                      <Tooltip title="Chat Status" arrow>
                        <Typography variant="sigma">CHAT</Typography>
                      </Tooltip>
                    </Th>
                    <Th className="w-16">
                      <Tooltip title="Pre Estimate Status" arrow>
                        <Typography variant="sigma">PRE</Typography>
                      </Tooltip>
                    </Th>
                    <Th className="w-16">
                      <Tooltip title="REV Estimate Status" arrow>
                        <Typography variant="sigma">POR</Typography>
                      </Tooltip>
                    </Th>
                    <Th className="w-16">
                      <Tooltip title="ADAS Estimate Status" arrow>
                        <Typography variant="sigma">POA</Typography>
                      </Tooltip>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {claimsListDataRb.map((claim, index) => (
                    <Tr key={claim.id}>
                      <Td className="p-0">
                        <a className="list-view-detail" href={`/claims/claim-details/${claim.urlkey}`}>
                          <Tooltip title={claim.vehicle && claim.vehicle !== '0 null null' ? claim.vehicle : 'No Vehicle Selected'} arrow>
                            <span className="max-150">{claim.vehicle && claim.vehicle !== '0 null null' ? claim.vehicle : 'No Vehicle Selected'}</span>
                          </Tooltip>
                        </a>
                      </Td>
                      <Td className="p-0 hidden-mobile">
                        {claim.vehicleVin && (
                          <span className="list-vin-number"
                            onClick={() => handleCopy(claim.vehicleVin)}>
                            <Tooltip title={copiedString === claim.vehicleVin ? "Copied!" : "Copy VIN Number"} arrow>
                              <span className="vin-number">{claim.vehicleVin && claim.vehicleVin !== 'null' ? claim.vehicleVin : 'N/A'}</span>
                              <svg data-icon-name="copyIcon" viewBox="0 0 18 18" width="18" height="18" aria-hidden="true" sandboxuid="4">
                                <path fill="#fff" fillRule="evenodd" d="M8 7v7h5V7H8zM3.99 2h7.942v2H4.985v8H3V2.995A1 1 0 013.99 2zM6 5.996c0-.55.446-.996.998-.996h7.004c.55 0 .998.445.998.996v9.008c0 .55-.446.996-.998.996H6.998A.996.996 0 016 15.004V5.996z" sandboxuid="4"></path>
                              </svg>
                            </Tooltip>
                          </span>
                        )}
                      </Td>
                      <Td className="p-0 hidden-mb">
                        <span className={claim.tcEstimate !== 0 ? "text-green-600" : "text-slate-300"}>${claim.tcEstimate}</span>
                      </Td>
                      <Td className="p-0 hidden-mb">
                        <span className={claim.estimateApproved ? "text-green-600" : "text-slate-300"}>${claim.humanEstimate}</span>
                      </Td>
                      {user.superAdmin && (
                        <Td className="p-0 hidden-mb">
                          <span className="tooltip-table" onClick={() => handleCopy(claim.user)}>
                            <Tooltip title={copiedString === claim.user ? "Copied!" : claim.user} arrow>
                              <span className="max-90">{claim.user}</span>
                              <svg data-icon-name="copyIcon" viewBox="0 0 18 18" width="18" height="18" aria-hidden="true" sandboxuid="4">
                                <path fill="#fff" fillRule="evenodd" d="M8 7v7h5V7H8zM3.99 2h7.942v2H4.985v8H3V2.995A1 1 0 013.99 2zM6 5.996c0-.55.446-.996.998-.996h7.004c.55 0 .998.445.998.996v9.008c0 .55-.446.996-.998.996H6.998A.996.996 0 016 15.004V5.996z" sandboxuid="4"></path>
                              </svg>
                            </Tooltip>
                          </span>
                        </Td>
                      )}
                      <Td className="p-0 hidden-mb">
                        {claim.createdAt}
                      </Td>
                      <Td className="p-0">
                        <Tooltip title={claim.messStep > 0 ? `(${claim.messStep}/8)` : "Sms sent!"} arrow>
                          <span onClick={() => openChatLink(claim.tcUrlKey)} className={`tc-actions-stt chat ${claim.messClass}`}>&nbsp;</span>
                        </Tooltip>
                      </Td>
                      <Td className="p-0">
                        {uP !== 'PostEstimate' ? (
                          <Tooltip title={claim.estStatus ? capitalizeStr(claim.estStatus) : "Pending"} arrow>
                            <span onClick={() => generateEstimate(claim.urlkey)} className={`tc-actions-stt generate-estimate ${claim.estStatus ? processStatusMap[claim.estStatus] : ''}`}>&nbsp;</span>
                          </Tooltip>
                        ) : (
                          <span className={`tc-actions-stt generate-estimate disabled`}>&nbsp;</span>
                        )}
                      </Td>
                      <Td className="p-0">
                        {uP !== 'PreEst' ? (
                          <Tooltip title={claim.revStatus ? capitalizeStr(claim.revStatus) : "Pending"} arrow>
                            <span onClick={() => applyAllowance(claim.urlkey)} className={`tc-actions-stt apply-allowance ${claim.revStatus ? processStatusMap[claim.revStatus] : ''}`}>&nbsp;</span>
                          </Tooltip>
                        ) : (
                          <span className={`tc-actions-stt apply-allowance disabled`}>&nbsp;</span>
                        )}
                      </Td>
                      <Td className="p-0">
                        {uP !== 'PreEst' ? (
                          <Tooltip title={claim.adasStatus ? capitalizeStr(claim.adasStatus) : "Pending"} arrow>
                            <span onClick={() => triggerAdas(claim.urlkey)} className={`tc-actions-stt trigger-adas ${claim.adasStatus ? processStatusMap[claim.adasStatus] : ''}`}>&nbsp;</span>
                          </Tooltip>
                        ) : (
                          <span className={`tc-actions-stt trigger-adas disabled`}>&nbsp;</span>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Toolbar pageCount={pageCount} 
                      activePage={activePage}
                      updatePageSize={updatePageSize}
                      pageSize={pageSize}
                      updateActivePage={updateActivePage}
              />
            </div>
          )}
        </Box>
      </ContentLayout>
      </div>
    </div>
  );
}